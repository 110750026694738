/*
import {ModalAlarmEditSubscribersPage} from './alarms/modal-alarm-edit-subscribers/modal-alarm-edit-subscribers.page';
import {ModalAlarmAddSubscriberPage} from './alarms/modal-alarm-add-subscriber/modal-alarm-add-subscriber.page';
import {ModalAlarmSettingsPage} from './alarms/modal-alarm-settings/modal-alarm-settings.page';

import {ModalAlarmHistoryPage} from './alarms/modal-alarm-history/modal-alarm-history.page';
import {ModalAlarmSettingsAddRulePage} from './alarms/modal-alarm-settings-add-rule/modal-alarm-settings-add-rule.page';
import {ModalReportPagePage} from './reports/modal-report-page/modal-report-page.page';
import {ModalChartSettingsPage} from './dashboard/modal-chart-settings/modal-chart-settings.page';
import {ModalPresentReportPage} from './reports/modal-present-report/modal-present-report.page';


import {DebugPage} from './debug/debug.page';
import {ModalManualReadingPage} from './reports/modal-manual-reading/modal-manual-reading.page';
import {ModalPermissionsWizardPage} from './modal-permissions-wizard/modal-permissions-wizard.page';

import {ModalPermissionsAppleWizardPage} from './modal-permissions-apple-wizard/modal-permissions-apple-wizard.page';

import {ModalFloorplanSettingsPage} from './dashboard/modal-floorplan-settings/modal-floorplan-settings.page';


//import { HttpLinkModule } from 'apollo-angular-link-http';


import {ModalHeatmapSettingsPage} from './dashboard/modal-heatmap-settings/modal-heatmap-settings.page';

import {ModalStationActionsPage} from './stations/modal-station-actions/modal-station-actions.page';
import {ModalTreePage} from './modal-tree/modal-tree.page';
import {CustomDialogPage} from './utils/custom-dialog/custom-dialog.page';
import {ModalAlarmStateCommentPage} from './alarms/modal-alarm-state-comment/modal-alarm-state-comment.page';
import {ModalAddDashboardPanelPage} from './dashboard/modal-add-dashboard-panel/modal-add-dashboard-panel.page';

import {ModalAlarmAddAlarmPage} from './alarms/modal-alarm-add-alarm/modal-alarm-add-alarm.page';
import {ModalStationPresentPage} from './stations/modal-station-present/modal-station-present.page';
import {ModalUsersEditPage} from './users/modal-users-edit/modal-users-edit.page';
import {ModalEditDashboardPanelPage} from './dashboard/modal-edit-dashboard-panel/modal-edit-dashboard-panel.page';
import {ModalAlarmEditAlarmPage} from './alarms/modal-alarm-edit-alarm/modal-alarm-edit-alarm.page';
import {ModalAlarmEditAllAlarmsPage} from './alarms/modal-alarm-edit-all-alarms/modal-alarm-edit-all-alarms.page';

import {ModalStationFormulasPage} from './stations/modal-station-formulas/modal-station-formulas.page';
import { ModalEditDataPage } from './data-manipulation/modal-edit-data/modal-edit-data.page';
import { ModalUsersAddPage } from './users/modal-users-add/modal-users-add.page';
import { ModalLiveDataListApplicationsPage } from './livedata/modal-live-data-list-applications/modal-live-data-list-applications.page';
import { ModalAddSubscriptionPage } from './subscriptions/modal-add-subscription/modal-add-subscription.page';
import { ModalEditSubscriptionPage } from './subscriptions/modal-edit-subscription/modal-edit-subscription.page';
import { PasswordForgotPage } from './login/password-forgot/password-forgot.page';
import { ModalMarkSensorFloorplanPage } from './dashboard/modal-add-dashboard-panel/modal-mark-sensor-floorplan/modal-mark-sensor-floorplan.page';
*/

import { PasswordSetPage } from './login/password-set/password-set.page';
import {PopoverActionsComponent} from './stations/popover-actions/popover-actions.component';
import {UsersPopoverActionsComponent} from './users/users-popover-actions/users-popover-actions.component';
import { PasswordForgotPageModule } from './login/password-forgot/password-forgot.module';
import {ToastControllerComponent} from './toast-controller/toast-controller.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {ComponentsModule} from './components/components.module';
import {Network} from '@awesome-cordova-plugins/network/ngx';
import { GridsterModule } from 'angular-gridster2';
import { Storage } from '@ionic/storage';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HighchartsChartModule } from 'highcharts-angular';
import {GraphQLModule} from './apollo.config';
import {ModalControllerComponent} from './modal-controller/modal-controller.component';
import { RedirectComponent } from './login/redirect/redirect.component';
import { FilterPipe } from './utils/pipes/filter.pipe';

@NgModule({
    declarations: [
        AppComponent,
        /*
        ModalStationFormulasPage,
       
      
        ModalAlarmEditAlarmPage,
        ModalAlarmStateCommentPage,
        ModalAlarmEditSubscribersPage,
        ModalAlarmAddSubscriberPage,
        ModalAlarmSettingsPage,
        ModalAlarmHistoryPage,
        ModalAlarmSettingsAddRulePage,
        ModalAlarmAddAlarmPage,
        ModalAlarmEditAllAlarmsPage,
        ModalReportPagePage,
        ModalChartSettingsPage,
        ModalPresentReportPage,
        ModalManualReadingPage,
        ModalPermissionsWizardPage,
        ModalPermissionsAppleWizardPage,
        ModalFloorplanSettingsPage,
        ModalHeatmapSettingsPage,
        ModalStationActionsPage,
        ModalTreePage,
        ModalStationPresentPage,
        ModalAddDashboardPanelPage,
        ModalEditDashboardPanelPage,
        ModalEditDataPage,
        ModalUsersAddPage,
        ModalMarkSensorFloorplanPage,
        DebugPage,
        ModalUsersEditPage,
        CustomDialogPage,
        ModalLiveDataListApplicationsPage,
  
        ModalAddSubscriptionPage,
        ModalEditSubscriptionPage,
      */
        ModalControllerComponent,
        ToastControllerComponent,
        PopoverActionsComponent,
        UsersPopoverActionsComponent,
        PasswordSetPage,
        RedirectComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (HttpLoaderFactory),
                deps: [HttpClient]
            }
        }),
        IonicModule.forRoot({ menuType: 'overlay' }),
        AppRoutingModule,
        GraphQLModule,
        HighchartsChartModule,
        FormsModule,
        GridsterModule,
        ColorPickerModule,
        ReactiveFormsModule,
        PasswordForgotPageModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        ComponentsModule,
    ],
    providers: [
        StatusBar,
        SplashScreen,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        ModalControllerComponent,
        ToastControllerComponent,
        Network,
        Storage
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent]
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
    var version = 13;
    return new TranslateHttpLoader(http, './assets/i18n/', '.json?cb=' + version);

}
