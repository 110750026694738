<ion-header>
  <ion-toolbar>
    <ion-title>debug</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <p *ngFor="let line of log">{{line}}</p>
</ion-content>


<ion-footer>
  <ion-toolbar>
    <ion-row>
      <ion-col (click)="goBack()">
        <ion-button expand="block" color="light">
          <ion-icon name="arrow-back"></ion-icon>
          close
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-toolbar>
</ion-footer>