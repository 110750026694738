import {Component, OnDestroy, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
//import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
//import { VoiceRecorder, VoiceRecorderPlugin, RecordingData, GenericResponse, CurrentRecordingStatus } from 'capacitor-voice-recorder';
declare let cordova: any;

@Component({
  selector: 'app-debug',
  templateUrl: './debug.page.html',
  styleUrls: ['./debug.page.scss'],
})
export class DebugPage implements OnInit, OnDestroy {
  public log = [];
  public debug = '';
  public celsius = '';
  public to: any;

  constructor(private selfModalCtrl: ModalController) {
      this.startProbe();
  }

  ngOnInit() {
    //this.prepareProbe();
  }

  ngOnDestroy() {
      clearInterval(this.to);
      if (typeof cordova !== 'undefined' && cordova && cordova.plugins && cordova.plugins.TempLive) {
          cordova.plugins.TempLive.stop({}, success => {
              if (success[0] === 'S') {
                  this.debug = success;
                  this.updateLog(this.debug);
              }
              console.log(success);
          }, failure => {
              console.log(failure);
          });
          this.log.length = 0;
      }
  }

  updateCelsius(response: string): string {
      if ( response.split('-')[2] === '1' ) {
          // Convert to Celsius
          if (Number(response.substring(12, response.length)) < 0) {
              return (((Number(response.substring(12, response.length))/10)-32)*5/9).toFixed(1);
          } else if ( Number(response.substring(12, response.length)) > 0 ) {
              return (((Number(response.substring(12, response.length))/10)-32)*5/9).toFixed(1);
          } else {
              return '0.0';
          }
      }
  }

  updateLog(message: string) {
      const now = new Date();
      const hh = now.getHours();
      const mm = now.getMinutes();
      const ss = now.getSeconds();
      this.log.push(hh + ':' + mm + ':' + ss + ' - ' + message);
  }

  /*
  prepareProbe() {
    console.log('prepare probe');

    try {
      VoiceRecorder.canDeviceVoiceRecord().then((result: GenericResponse) => {
        if (!result.value) { return; }
        VoiceRecorder.hasAudioRecordingPermission().then((result: GenericResponse) => {
            if (!result.value) { return; }
            this.startProbe();


          
          //if ( (typeof cordova !== 'undefined') && cordova && cordova.plugins && cordova.plugins.TempLive) {
              //const errorCallback = (e) => console.error(e);

              //const successCallback = (isAuthorized) => {
                //  if (!isAuthorized) {
                  //    this.diagnostic.requestMicrophoneAuthorization().then((status) => {
                    //      if( status === cordova.plugins.diagnostic.permissionStatus.GRANTED ) {
                              this.startProbe();
                      //    }
                     // }).catch(errorCallback);
                 // } else {
                   //   this.startProbe();
                 // }
             // };

             // this.diagnostic.isMicrophoneAuthorized().then(successCallback).catch(errorCallback);

          //}
        
          });
          
      });
    } catch(e) {
        console.log('Error on prepareProbe');
        console.log(e);
    }
  }
  */

  startProbe() {
      console.log('start probe');
      cordova.plugins.TempLive.start({}, success => {
          if (this.debug === '') {
              this.debug = success;
              // TODO: 'XXX' ONLY FOR TESTING
              // this.debug = 'D-A580-1-OK-717';
              this.updateLog(this.debug);
              if (success[0] == 'D' || success[0] == 'H') {
                  this.celsius = this.updateCelsius(this.debug);
              }
          }
          if (success !== this.debug && (success[0] == 'D' || success[0] == 'H')) {
              this.debug = success;
              this.updateLog(this.debug);
              this.celsius = this.updateCelsius(success);
          }
          this.to = setInterval(() => {
              cordova.plugins.TempLive.read({},
                  response => {
                      if (response !== this.debug && (response[0] == 'D' || response[0] == 'H')) {
                          this.debug = response;
                          this.updateLog(this.debug);
                          this.celsius = this.updateCelsius(response);
                          // 'XXX'
                          // this.celsius = this.updateCelsius('D-A580-1-OK-717');
                      }
                  },
                  error => {
                      console.log(error);
                  });
          }, 1000);
      }, failure => {
          console.log(failure);
      });
  }
  goBack() {
      this.selfModalCtrl.dismiss(null);
  }
}
