import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { ApiService } from './services/api.service';
import { DataService } from './services/data.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { ModalControllerComponent } from './modal-controller/modal-controller.component';
import { DebugPage } from './debug/debug.page';
import { TranslateService } from '@ngx-translate/core';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { menuController } from '@ionic/core';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

    private subscriptions = [];

    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private apiService: ApiService,
        public dataService: DataService,
        private router: Router,
        private storage: Storage,
        private modalController: ModalControllerComponent,
        private translate: TranslateService,
        private network: Network,
        private cdr: ChangeDetectorRef,
        private route: ActivatedRoute,
        private http: HttpClient,
    ) {

        let browserIsMobile = this.primaryMobileDetection(navigator.userAgent || navigator.vendor || window['opera']);

        // alert('Primary Mobile Browser detection states: ' + browserIsMobile);

        if (browserIsMobile === true) {
            if (this.platform.is('mobile') || this.platform.is('desktop')) {
                this.dataService.setPlatform('mobile');
            }

            /**
             * If tablet is detected, device screen is large enough so we override it with tablet.
             */
            if (this.platform.is('tablet')) {
                this.dataService.setPlatform('tablet');
            }

            if (this.platform.is('desktop')) {
                this.dataService.setPlatform('desktop');
            }

            /*
            alert('mobile ' + this.platform.is('mobile'));
            alert('tablet ' + this.platform.is('tablet'));
            alert('desktop ' + this.platform.is('desktop'));
            */

        } else {
            this.dataService.setPlatform('desktop');
        }

        /**
         * DEV: FORCE platform
         */
        // this.dataService.setPlatform('mobile');
        console.log('mobile', this.platform.is('mobile'));
        console.log('tablet', this.platform.is('tablet'));
        console.log('desktop', this.platform.is('desktop'));
        console.log('This device is detected as a set of the following platforms: ', this.platform.platforms());

        //if platform is at the same time true for tablet and desktop then consider it as desktop
        if (this.platform.is('desktop') && this.platform.is('tablet')) {
            this.dataService.setPlatform('desktop');
        }

        this.initializeApp();
        this.initTranslate();

        this.subscriptions.push(this.network.onDisconnect().subscribe(() => {
            console.log('network was disconnected :-(');
            this.router.navigateByUrl('login');
        }));
        this.subscriptions.push(this.network.onConnect().subscribe(() => {
            console.log('network connected localstorage => ', localStorage.getItem('autologin'));
            // this.ngOnInit();
        }));
    }


    primaryMobileDetection(a) {
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a)
            ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))
        ) {
            return true;
        } else {
            return false;
        }
    }


    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    initTranslate() {
        // Set the default language for translation strings, and the current language.
        this.translate.setDefaultLang('en');

        if (this.translate.getBrowserLang() !== undefined) {
            this.translate.use(this.translate.getBrowserLang());
        } else {
            this.translate.use('en'); // Set your language here
        }

        // this.translate.use('cn'); // Set your language here
    }

    async initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.statusBar.overlaysWebView(true);
            this.splashScreen.hide();
            this.cdr.detectChanges();
        });
    }

    async handleSSOLoginResponse(): Promise<any> {
        
        let tokenSSOLogin = window.location.search.replace("?token=", "");

        await this.apiService.ssoAuthenticate(tokenSSOLogin, (response) => {
            
            if (response.data.authenticate) {

                localStorage.setItem('token', tokenSSOLogin);
                localStorage.setItem('rootCtxId', response.data.authenticate.contextId);
                localStorage.setItem('permissions', JSON.stringify(response.data.authenticate.permissions));

                let expiresIn = JSON.parse(atob(tokenSSOLogin.split('.')[1])).exp;

                const now = new Date();
                expiresIn = (new Date(expiresIn * 1000).getTime() - now.getTime()) / 1000;
                console.log('Session expires in ' + expiresIn + ' seconds');

                this.dataService.setAuthTimer(expiresIn, this.router, this.apiService);

                // this.dataService.setAuthTimer(60, this.router, this.apiService); // TESTING 60s token expiration

                this.dataService.setPermissions(response.data.authenticate.permissions);
                this.dataService.setCurrentCtxId(response.data.authenticate.contextId);
                this.dataService.setCurrentCtxIdType('');
                this.apiService.queryCurrentContext(this.dataService.getCurrentCtxId(), this.dataService.getCurrentCtxIdType(), responseCtx => {
                    this.dataService.initContext(responseCtx);
                    this.dataService.chooseContext(this.dataService.contextChildren[0], 0, () => {
                        this.apiService.queryCurrentContext(this.dataService.getCurrentCtxId(), this.dataService.getCurrentCtxIdType(), responseCtx2 => {
                            this.dataService.updateContext(responseCtx2);
                        });
                    });
                });
                this.dataService.reloadSubject.next({ key: 'dashboard', value: null }); // XXX TESTING THIS TO SOLVE BUG OF DASHBOARD NOT REFRESHING AFTER SESSION EXPIRY
                this.router.navigateByUrl('home');
            }
        });
    }

    ngOnInit() {


        this.route.queryParams.subscribe(params => {
            var tokenFromParam = null;

            /**
            * AUTO LOGIN BY DEFAULT SINCE 2-JUNE-2021:
            */
            localStorage.setItem('autologin', 'true');


            /**
             * If it is a redirect response from SSO Login then authenticate using the generated token
             */
            if (window.location.pathname.includes('/sso')) {
                this.handleSSOLoginResponse();
                return;
            }


            /**
             * Set new password.
             */
            let request = '';
            console.log(window.location);
            if (window.location.search[0] === '?') {
                request = window.location.search.split('?')[1].split('=')[1];
                this.router.navigate(['passwd'], { queryParams: { request: request } });
                return;
            }

            // }

            if (window.location.search[0] === '?') {
                tokenFromParam = window.location.search.split('?')[1].split('=')[1];
            }

            console.log(params);
            const test = params['token']; // Assuming the token is passed as a query param 'token'
            console.log('Authorization Token:', tokenFromParam);

            if (tokenFromParam) {
                //SET TOKEN TO LOCAL STORAGE
                localStorage.setItem('token', tokenFromParam);

                // CHECK permissions load current context and redirect to home
                this.dataService.setPermissions(JSON.parse(localStorage.getItem('permissions')));
                this.dataService.setCurrentCtxId(Number(localStorage.getItem('rootCtxId')));
                this.dataService.setCurrentCtxIdType(''); // TODO: grab rootCtxIdType at the moment of login
                console.log('QueryContext');
                this.apiService.queryCurrentContext(this.dataService.getCurrentCtxId(), this.dataService.getCurrentCtxIdType(), responseCtx => {
                    this.dataService.initContext(responseCtx);
                    this.dataService.chooseContext(this.dataService.contextChildren[0], 0, () => {
                        this.apiService.queryCurrentContext(this.dataService.getCurrentCtxId(), this.dataService.getCurrentCtxIdType(), responseCtx2 => {
                            this.dataService.updateContext(responseCtx2);
                        });
                    });
                });
                //this.router.navigateByUrl('home');
            }

            /** Auto Login if Token is available;
             *  Checks if has token and query the context, otherwise redirects to login.
             */
            if ('true' === localStorage.getItem('autologin')) { //TODO - DO NOT CONSIDER IT WHEN endpoint is /redirect

                // Check expiration date here.
                if (this.dataService.checkTokenIsExpired(this.router, this.apiService) == false) {
                    console.log('Token is not expired!');
                    const token = localStorage.getItem('token');
                    if (token) {
                        this.dataService.setPermissions(JSON.parse(localStorage.getItem('permissions')));
                        this.dataService.setCurrentCtxId(Number(localStorage.getItem('rootCtxId')));
                        this.dataService.setCurrentCtxIdType(''); // TODO: grab rootCtxIdType at the moment of login
                        console.log('QueryContext');
                        this.apiService.queryCurrentContext(this.dataService.getCurrentCtxId(), this.dataService.getCurrentCtxIdType(), responseCtx => {
                            this.dataService.initContext(responseCtx);
                            this.dataService.chooseContext(this.dataService.contextChildren[0], 0, () => {
                                this.apiService.queryCurrentContext(this.dataService.getCurrentCtxId(), this.dataService.getCurrentCtxIdType(), responseCtx2 => {
                                    this.dataService.updateContext(responseCtx2);
                                });
                            });
                        });
                        this.router.navigateByUrl('home');
                    } else {
                        this.router.navigateByUrl('login');
                    }
                } else {
                    console.log('Token is expired!');
                    this.dataService.logout(this.router, this.apiService);
                }

            } else {
                this.router.navigateByUrl('login');
            }

            /*
          const headers = event.url.headers;
          if (headers.has('Authorization')) {
            const token = headers.get('Authorization');
            this.storage.set('Authorization', token);
            console.log('Authorization token extracted:', token);
          }
          */


        }
        );

    }

    logout() {
        //this.menu.close();
        menuController.toggle();
        this.dataService.logout(this.router, this.apiService);
    }

    getContext() {
        console.log('getContext');
        this.apiService.queryCurrentContext(this.dataService.getCurrentCtxId(), this.dataService.getCurrentCtxIdType(), response => {
            this.dataService.updateContext(response);
        });
    }

    chooseLevel(level: any, index: any) {
        this.dataService.chooseContext(level, index, () => {
            if (level.typeId !== '599') {
                this.getContext();
            }
        });
    }

    menuClosed() {
        console.log('this.router.url', this.router.url);
        switch (this.router.url) {
            case '/home/dashboard':
                this.dataService.reloadSubject.next({ key: 'dashboard', value: null });
                break;
            case '/home/alarms':
                this.dataService.reloadSubject.next({ key: 'alarms', value: null });
                break;
            case '/home/alarmsdiagnostic':
                this.dataService.reloadSubject.next({ key: 'alarmsdiagnostic', value: null });
                break;
            case '/home/reports':
                this.dataService.reloadSubject.next({ key: 'reports', value: null });
                break;
            case '/home/map':
                this.dataService.reloadSubject.next({ key: 'map', value: null });
                break;
            case '/home/dataexplorer':
                this.dataService.reloadSubject.next({ key: 'dataexplorer', value: null });
                break;
            case '/home/stations':
                this.dataService.reloadSubject.next({ key: 'stations', value: null });
                break;
            case '/home/parkingmap':
                this.dataService.reloadSubject.next({ key: 'parkingmap', value: null });
                break;
            case '/home/document':
                this.dataService.reloadSubject.next({ key: 'document', value: null });
                break;
            case '/home/users':
                this.dataService.reloadSubject.next({ key: 'users', value: null });
                break;
            case '/home/datamanipulation':
                this.dataService.reloadSubject.next({ key: 'datamanipulation', value: null });
                break;
            case '/home/livedata':
                this.dataService.reloadSubject.next({ key: 'livedata', value: null });
                break;
            case '/home/subscriptions':
                this.dataService.reloadSubject.next({ key: 'subscriptions', value: null });
                break;
            case '/home/servicetask':
                this.dataService.reloadSubject.next({ key: 'servicetask', value: null });
                break;
            default:
                console.log('Nothing to do on this route: ', this.router.url);
                break;
        }
    }

    openDebug() {
        console.log('open debug');
        this.modalController.presentModal(DebugPage, {}, (result) => {
            console.log('CLOSED DEBUG');
        });
    }

    closeMenu() {
        console.log('CLOSE MENU');
    }
}
