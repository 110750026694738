import { Injectable } from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import { Observable, tap, catchError, throwError } from 'rxjs';
//import gql from 'graphql-tag';


import {DataService} from '../services/data.service';
//import bigInt from "big-integer";


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public loading = false;
  constructor(private apollo: Apollo,
              private dataService: DataService) { }


  private convertTypeIdToLabel(idType: string): string {
    switch(idType) {
      case this.dataService.TYPE_THING: //599
        idType = this.dataService.TYPE_THING_LABEL;
      break;
      case this.dataService.TYPE_FACILITY: //504
        idType = this.dataService.TYPE_FACILITY_LABEL;
      break;
      case this.dataService.TYPE_CLIENT: //502
        idType = this.dataService.TYPE_CLIENT_LABEL;
      break;
      case this.dataService.TYPE_ORGANIZATION: //501
        idType = this.dataService.TYPE_ORGANIZATION_LABEL;
      break;
    }

    return idType;
  }

  private gql_rest_login() {
      return gql`
          query auth($username: String, $password: String) {
            auth(input: {username: $username, password: $password}) @rest(type: "Post", path: "authenticate", method: "POST") {
              token
              refreshToken
              contextId
              permissions {
                code
                read
                create
                update
                delete
              }
            }
          }
      `;
  }

  private gql_rest_sso_login() {
    return gql`
        query ssoLogin($email: String) {
          ssoLogin(input: {email: $email}) @rest(type: "Get", path: "sso-login", method: "GET")
        }
    `;
  }
  
  private gql_rest_ssoAuthenticate() {
    return gql`
    query authenticate($token: String) {
      authenticate(input: {token: $token}) @rest(type: "Post", path: "sso-authenticate", method: "POST") {
        contextId
        permissions {
          code
          read
          create
          update
          delete
        }
      }
    }
    `;
  }

  private gql_rest_forgotPassword() {
    return gql`
      query forgotPassword($hostname: String, $email: String) {
        forgot(input: {hostname: $hostname, email: $email}) @rest(type: "Post", path: "forgotpassword", method: "POST")
      }
    `;
  }

  private gql_rest_updatePassword() {
    return gql`
      query updatePassword($requestId: String, $password: String) {
        update(input: {requestId: $requestId, password: $password}) @rest(type: "Post", path: "updatepassword", method: "POST")
      }
    `;
  }


  private gql_mutation_forgotPassword() {
    return gql`
      mutation forgotPassword($hostname: String, $email: String) {
        forgotPassword(hostname: $hostname, email: $email)
      }
    `;
  }


  private gql_mutation_uploadFloorplanImage(){
    return gql`
      mutation uploadFloorplan($image: String){
        uploadFloorplan(image: $image){
          url
          width
          height
        }
      }
    `;
  }

  private gql_query_current_context() {
      return gql`
        query currentContext($id: Int, $contextTypeId: String) {
          currentContext(id: $id, contextTypeId: $contextTypeId) {
          id
          name
          typeId
          children {
            id
            name
            typeId
          }
        }
      }`;
  }

    private gql_query_context_tree() {
        return gql`
        query contextTree($id: Int, $contextTypeId:String){
          contextTree(id: $id, contextTypeId: $contextTypeId){
            id
            name
            typeId
            children{
              id
              name
              typeId
            }
            untied{
              id
              typeId
              name
            }
          }
        }`;
    }


    private gql_query_userTemplates(){
      return gql`
        query userTemplateByProfile($id: Int){
          userTemplateByProfile(id: $id){
            tid
            name
          }
        }
      `;
    }

    private gql_query_statistics_under_context() {
        return gql`
          query statisticsUnderContext($id: Int, $contextTypeId: String) {
              statisticsUnderContext(id: $id, contextTypeId: $contextTypeId) {
                alarms {
                  id
                  status
                  number
                }
                things {
                  id
                  status
                  number
                }
              }
          }`;
    }

    private gql_query_dashboard() {
    return gql`
      query dashboard($id: Int, $contextTypeId: String) {
          dashboard(id: $id, contextTypeId: $contextTypeId) {
            panels {
              id
              name
              typeId
              x
              y
              width
              height
              properties {
                key
                value
              }
            }
          }
        }`;
  }

  private gql_query_location() {
      return gql`
        query location($id: Int, $contextTypeId: String){
          location(id: $id, contextTypeId: $contextTypeId) {
            locationId
            facilityId
            label
            street
            zipcode
            state
            country
            locationLastUpdated
            thingStatusColor
            longitude
            latitude
            timeZone
            gpsLatitudeSensorId
            gpsLongitudeSensorId
          }
        }
      `;
  }

    private gql_query_values() {
        return gql`
          query values($id: String, $from: String, $to: String, $aggregation: String, $group: String, $limit: String, $minVal: Float, $maxVal: Float) {
            values(id: $id, from: $from, to: $to, aggregation: $aggregation, group: $group, limit: $limit, minVal: $minVal, maxVal: $maxVal){
                sensor
                dataPoints{
                  value
                  timestampLocal
                  timestampUtc
                }
              }
          }`;
    }

    private gql_query_values_advanced() {
      return gql`
        query valuesAdvanced($id: String, $from: String, $to: String, $aggregation: String, $group: String, $groupValue: String, $andGroup: String, $andGroupValue: String) {
            valuesAdvanced(id: $id, from: $from, to: $to, aggregation: $aggregation, group: $group, groupValue: $groupValue, andGroup: $andGroup, andGroupValue: $andGroupValue){
                sensor
                timezone
                aggregatedDataPoints {
                    value
                    hour
                    day
                    month
                    year
                    weekday
                    weekoftheyear
                }
              }
          }`;
    }

    private gql_query_parking_list() {
      return gql`
        query parkingList($id: Int, $idType: String){
          parkingList(id: $id, idType: $idType){
            name
            longitude
            latitude
            timesession{
              tsin
              tsout
              tslast
            }
            timeout
          }
        }
      `;
    }

    private gql_query_locations_parking() {
        return gql`
        query locationParking($id: Int, $idType: String){
          locationParking(id: $id, idType: $idType){
            lid
            fid
            longitude
            latitude
            sid
            sname
            monitored
            timeout
          }
        }
      `;
    }

    private gql_query_values_parking() {
      return gql`
        query valuesParking($id: String, $from: String, $to: String, $limit: String){
          valuesParking(id: $id, from: $from, to: $to, limit: $limit){
            tsin
            tsout
          }
        }
      `;
    }

    private gql_query_alarms_diagnostic_under_context() {
      return gql`
        query alarmsDiagnosticUnderContext($id: Int, $contextTypeId: String) {
          alarmsDiagnosticUnderContext(id: $id, contextTypeId: $contextTypeId){
              id
              name
              description
              occurrences
              autoCloseable
              thingId
              thingName
              alarmActivationStatusTypeName
              activationStatusTypeId
              alarmStatusTypeName
              levelTypeName
              levelTypeId
              statusTypeName
              statusTypeId
              timestamp
              timeZone
              profileName
              subscribers {
                id
                name
                contact
                userId
                typeName
                typeId
              }
              rules {
                id
                name
                operatorName
                operatorTypeId
                value
                sensor
                sensorId
                startTime
                endTime
              }
            }
        }`;
  }


    private gql_query_alarms_under_context() {
        return gql`
          query alarmsUnderContext($id: Int, $contextTypeId: String) {
            alarmsUnderContext(id: $id, contextTypeId: $contextTypeId){
                id
                name
                description
                occurrences
                autoCloseable
                thingId
                thingName
                alarmActivationStatusTypeName
                activationStatusTypeId
                alarmStatusTypeName
                levelTypeName
                levelTypeId
                statusTypeName
                statusTypeId
                timestamp
                timeZone
                profileName
                subscribers {
                  id
                  name
                  contact
                  userId
                  typeName
                  typeId
                }
                rules {
                  id
                  name
                  operatorName
                  operatorTypeId
                  value
                  sensor
                  sensorId
                  startTime
                  endTime
                }
              }
          }`;
    }

    private gql_subscribe_values() {
      return gql`
        subscription values($sensorId: String){
            values(sensorId: $sensorId) {
                sensor
                value
                timestamp
            }
        }
      `;
    }

    private gql_mutation_updateSensorValue() {
      return gql`
        mutation updateSensorValue($id: String, $timestamp: String, $value: Float, $comments: String){
          updateValue(id: $id, timestamp: $timestamp, value: $value, comments: $comments)
        }
        `;
    }
    private gql_mutation_createUser() {
      return gql`
        mutation createUser($hostname: String, $name: String, $position: String, $parentId: Int, $email: String, $mobile: String, $templateId: Int){
          createUser(hostname: $hostname, name: $name, position: $position, parentId: $parentId, email: $email, mobile: $mobile, templateId: $templateId) {
            id,
            created
          }
        }
      `;
    }

    private gql_mutation_deleteReport() {
        return gql`
        mutation deleteReport($id: String){
          deleteReport(id: $id)
        }
      `;
    }

    private gql_mutation_deleteAlarmSubscriber() {
      return gql`
        mutation deleteAlarmSubscriber($id: Int, $contactId: Int, $contactTypeId: Int){
          deleteAlarmSubscriber(id: $id, contactId: $contactId, contactTypeId: $contactTypeId)
        }
      `;
    }

    private gql_mutation_deleteAlarm() {
      return gql`
        mutation deleteAlarm($id: Int){
          deleteAlarm(id: $id)
        }
      `;
    }

    private gql_mutation_createAlarmSubscriber() {
        return gql`
        mutation createAlarmSubscriber($id: Int, $contactId: Int, $contactTypeId: Int){
          createAlarmSubscriber(id: $id, contactId: $contactId, contactTypeId: $contactTypeId)
        }
      `;
    }

    private gql_mutation_createAlarmSubscribers() {
      return gql`
      mutation createAlarmSubscribers($id: Int, $subscribers: [AlarmSubscribers]){
        createAlarmSubscribers(id: $id, subscribers: $subscribers)
      }
    `;
  }

    private gql_mutation_createAlarmStatusHistory() {
        return gql`
        mutation createAlarmStatusHistory($id: Int, $statusTypeId: Int, $comment: String){
          createAlarmStatusHistory(id: $id, statusTypeId: $statusTypeId, comment: $comment)
        }
      `;
    }

    private gql_mutation_livedata_start_application() {
      return gql`
      mutation liveDataStartApplication(
        $host: String,
        $port: String,
        $context: String,
        $application: String,
        $applicationManagerToken: String) {
        liveDataStartApplication(
          host: $host,
          port: $port,
          context: $context,
          application: $application,
          applicationManagerToken: $applicationManagerToken) 
        }
      `;
    }

    private gql_mutation_createDevice() {
      return gql`
        mutation createDevice($name: String, $serialId: String, $modelId: Int, $thingId: Int, $config: String){
          createDevice(name: $name, serialId: $serialId, modelId: $modelId, thingId: $thingId, config: $config)  {
              id
              created
            }
        }
      `;
    }

    private gql_mutation_createDownlink() {
      return gql`
        mutation createDownlink($id: Int, $eui: String, $confirmed: Boolean, $data: String, $fPort: Int) {
          createDownlink(id: $id, eui: $eui, confirmed: $confirmed, data: $data, fPort: $fPort)
        }
      `;
    }

    private gql_mutation_deleteDownlinkQueue() {
      return gql`
      mutation deleteDownlink($id: Int, $eui: String) {
        deleteDownlink(id: $id, eui: $eui)
        }
      `;
    }

    private gql_mutation_updateThingParent() {
      return gql`
        mutation updateThingParent($id: Int, $parentId: Int){
          updateThingParent(id: $id, parentId: $parentId)
        }
        `;
    }

    private gql_mutation_updateUserParent() {
      return gql`
        mutation updateUserParent($id: Int, $parentId: Int){
          updateUserParent(id: $id, parentId: $parentId)
        }
        `;
    }

    private gql_mutation_createCustomerSubscription() {
      return gql`
        mutation createCustomerSubscription($profileId: Int, $reference: String, $startDate: String, $endDate: String, $devices: [Controller2]){
          createCustomerSubscription(profileId: $profileId, reference: $reference, startDate: $startDate, endDate: $endDate, devices: $devices)
          { 
            id,
            created
          }
        }
      `;
    }

    private gql_mutation_updateCustomerSubscription() {
      return gql`
        mutation updateCustomerSubscription($id: Int, $reference: String, $startDate: String, $endDate: String, $devices: [Controller2]){
          updateCustomerSubscription(id: $id, reference: $reference, startDate: $startDate, endDate: $endDate, devices: $devices)
        }
      `;
    }

    private gql_mutation_createServiceTask() {
      return gql`
        mutation createServiceTask ($id: Int, $serviceTypeId: Int, $startDate: String, $endDate: String, $reference: String, $json: String) {
          createServiceTask (id: $id, serviceTypeId: $serviceTypeId, startDate: $startDate, endDate: $endDate, reference: $reference, json: $json) {
              id,
              created
          }
        }
      `;
    }

    private gql_mutation_updateServiceTask() {
      return gql`
        mutation updateServiceTask($id: Int, $startDate: String, $endDate: String, $reference: String, $json: String){
          updateServiceTask(id: $id, startDate: $startDate, endDate: $endDate, reference: $reference, json: $json)
        }
      `;
    }

    private gql_mutation_deleteServiceTask() {
      return gql`
        mutation deleteServiceTask($id: Int){
          deleteServiceTask(id: $id)
        }
      `;
    }

    private gql_query_types() {
      return gql`
        query types{
          types{
            alarmOperators{
              id
              name
            }
            contactTypes{
              id
              name
            }
            alarmAggregationTypes{
              id
              name
            }
            alarmStateTypes{
              id
              name
            }
            alarmLevelTypes{
              id
              name
            }
            deviceTypes{
              id
              name
            }
            dashboardPanelTypes{
              id
              name
            }
          }
        }
      `;
    }

    private gql_queryDeviceModels() {
      return gql`
        query deviceModels($id: Int){
            deviceModels(id: $id) {
                id
                name
            }
        }
      `;
    }

    private gql_queryDeviceLoraWANProfiles() {
      return gql`
        query deviceLoraWANProfiles($applicationId: Int){
          deviceLoraWANProfiles(applicationId: $applicationId){
            id
            name
            macv
            join
          }
        }
      `;
    }

    private gql_queryApplicationsByContext() {
      return gql`
        query applicationsByContext($ctx: String){
          applicationsByContext(ctx: $ctx){
            id
            name
          }
        }
      `;
    }

    private gql_query_users_by_thing() {
        return gql`
            query usersByThing($id: Int) {
              usersByThing(id: $id) {
                name
                id
                contact
                contactId
                contactTypeName
                contactTypeId
              }
            }
          `;
    }

    private gql_query_alarmHistory() {
      return gql`
        query alarmHistory($id: Int){
            alarmHistory(id: $id){
                userName
                statusName
                comments
                timestamp
                timeZone
                timestampReceived
            }
        }
      `;
    }

    private gql_deleteAlarmRule() {
      return gql`
        mutation deleteAlarmRule($id: Int, $ruleId: Int){
          deleteAlarmRule(id: $id, alarmRuleId: $ruleId)
        }
      `;
    }

    private gql_query_sensorsByThing() {
      return gql`
      query sensorsByThing($id: Int){
        sensorsByThing(id: $id){
            id
            name
          }
        }
      `;
    }

    private gql_createAlarmRule() {
      return gql`
      mutation createAlarmRule($id: Int, $operatorTypeId: Int, $value: Float, $sensorId: Int, $endTime: String, $startTime: String){
        createAlarmRule(id: $id, operatorTypeId: $operatorTypeId, value: $value, sensorId: $sensorId, endTime: $endTime, startTime: $startTime)
      }
      `;
    }

    private gql_updateAlarmRule() {
        return gql`
      mutation updateAlarmRule($id: Int, $alarmRuleId: Int, $operatorTypeId: Int, $value: Float, $startTime: String, $endTime: String ){
        updateAlarmRule(id: $id, alarmRuleId: $alarmRuleId, operatorTypeId: $operatorTypeId, value: $value, startTime: $startTime, endTime: $endTime)
      }
      `;
    }

    private gql_updateAlarmStatus() {
      return gql`
        mutation updateAlarmStatus($id: Int, $activationStatusTypeId: Int) {
            updateAlarmStatus(id: $id, activationStatusTypeId: $activationStatusTypeId)
        }
      `;
    }

    private gql_mutation_updateReport() {
      return gql`
        mutation updateReport($id: String, $data:String){
          updateReport(id: $id, data: $data)
        }
      `;
    }

    private gql_query_reports_under_context_paged() {
        return gql`
      query reportsUnderContext_paged($id: Int, $parentType: String, $under: Boolean, $pageNumber: Int, $entriesPerPage: Int){
        reportsUnderContext_paged(id: $id, parentType:$parentType, under:$under, pageNumber: $pageNumber, entriesPerPage: $entriesPerPage){
            reportInfo{
              reportButtonLabel
              template
            }
            reports{
              id
              meta{
                label
                parentId
                parentType
                createdDate
                expirationDate
                completedDate
                lastModified
                status
                userId
                ondemand
              }
              form{
                display
                components{
                  label
                  type
                  title
                  key
                  components
                }
              }
              data
            }
        }
      }
      `;
    }

    private gql_query_reports() {
      return gql`
      query reports($parentId: Int, $parentType: String){
          reports(parentId: $parentId, parentType:$parentType){
            reportInfo{
              reportButtonLabel
              template
            }
            reports{
              id
              meta{
                label
                parentId
                parentType
                createdDate
                expirationDate
                completedDate
                lastModified
                status
                userId
                ondemand
              }
              form{
                display
                components{
                  label
                  type
                  title
                  key
                  components
                }
              }
              data
            }
        }
        }
      `;
    }


    private gql_postDelivery() {
      return gql`
        mutation postDelivery($id: Int, $idType: String, $report: String){
            postDelivery(id: $id, idType: $idType, report: $report)
        }
      `;
    }

    private gql_queryDelivery() {
      return gql`
        query delivery($id: Int, $idType: String){
          delivery(id: $id, idType: $idType) {
            id
            meta{
                label
                parentId
                parentType
                createdDate
                expirationDate
                completedDate
                lastModified
                status
                userId
            }
            form{
              display
              components{
                label
                type
                title
                key
                components
              }
            }
            data
          }
        }
      `;
    }

    private gql_query_sensor_details() {
        return gql`
            query sensorDetails($id: Int){
              sensorDetails(id: $id){
                id
                name
                deviceSerial
                operationCode
                serial
                active
                thingName
                typeName
                subTypeId
                subTypeName
                deviceName
                inputTypeName
                timeZone
              }
            }
        `;
    }

    private gql_queryDeviceDetails() {
        return gql`
            query deviceDetails($id: Int){
              deviceDetails(id: $id){
                id
                name
                serial
                parentId
                parentName
                modelName
                modelTypeName
                lora {
                  appId
                  lastseen
                  devaddr
                  appskey
                  nwskey
                }
              }
            }
        `;
    }

    private gql_queryThingDetails() {
        return gql`
            query thingDetails($id: Int){
              thingDetails(id: $id){
                id
                name
                parentId
                parentName
                parentTypeId
                parentTypeName
                street
                zip
                city
                country
                state
                latitude
                longitude
              }
            }
        `;
    }

    private gql_queryFacilityDetails() {
        return gql`
            query facilityDetails($id: Int){
              facilityDetails(id: $id){
                id
                name
                type
                street
                zip
                city
                country
                state
                latitude
                longitude
              }
            }
        `;
    }

    private gql_queryClientDetails() {
        return gql`
            query clientDetails($id: Int){
              clientDetails(id: $id){
                id
                name
                type
                street
                zip
                city
                country
                state
                latitude
                longitude
              }
            }
        `;
    }

    private gql_queryOrganizationDetails() {
        return gql`
            query organizationDetails($id: Int){
              organizationDetails(id: $id){
                id
                name
                type
                street
                zip
                city
                country
                state
                latitude
                longitude
              }
            }
        `;
    }

    private gql_query_documents_under_context() {
      return gql`
        query documentsUnderContext($id: Int){
          documentsUnderContext(id: $id){
                id
                typeName
                parentId
                name
                url
                created
                timeZone
            }
        }
      `;
    }

    private gql_query_document_download() {
      return gql`
          query documentDownload($url: String){
            documentDownload(url: $url){
                payload
            }
          }
      `;
    }

    private gql_query_countries() {
      return gql`
        query countries {
          countries{
            id
            name
          }
        }
      `;
    }
    private gql_query_country_states() {
      return gql`
        query countryStates($id: String) {
          countryStates(id: $id) {
            id
            name
          }
        }
      `;
    }

    private gql_mutation_createOrganization() {
        return gql`
        mutation createOrganization ($name: String, $parentId: Int, $locationDetails: LocationDetails) {
          createOrganization (name: $name, parentId: $parentId, locationDetails: $locationDetails) {
            id,
            created
          }
        }
      `;
    }

    private gql_mutation_createClient() {
        return gql`
        mutation createClient ($name: String, $parentId: Int, $locationDetails: LocationDetails) {
          createClient (name: $name, parentId: $parentId, locationDetails: $locationDetails)
          { id, created }
        }
      `;
    }

    private gql_mutation_createFacility() {
        return gql`
        mutation createFacility ($name: String, $parentId: Int, $locationDetails: LocationDetails) {
          createFacility (name: $name, parentId: $parentId, locationDetails: $locationDetails)
          { id, created }
        }
      `;
    }

    private gql_mutation_createThing() {
        return gql`
        mutation createThing ($name: String, $parentId: Int, $parentType: String, $locationDetails: LocationDetails) {
          createThing (name: $name, parentId: $parentId, parentType: $parentType, locationDetails: $locationDetails){
              id,
              created
          }
        }
      `;
    }

    private gql_mutation_createSensor() {
      return gql`
      mutation createSensor(
        $name: String,
        $serial: Int,
        $deviceId: Int,
        $inputTypeId: Int,
        $subTypeId: Int,
        $thingId: Int
        ) {
        createSensor(
          name: $name,
          serial: $serial,
          deviceId: $deviceId,
          inputTypeId: $inputTypeId,
          subTypeId: $subTypeId,
          thingId: $thingId
        ) {
          id,
          created
        }
      }
    `;
  }

    private gql_mutation_deleteSensor() {
      return gql`
        mutation deleteSensor($id: Int){
          deleteSensor(id: $id)
        }
      `;
    }

    private gql_mutation_deleteController() {
        return gql`
        mutation deleteController($id: Int){
          deleteController(id: $id)
        }
      `;
    }

    private gql_mutation_deleteThing() {
        return gql`
        mutation deleteThing($id: Int){
          deleteThing(id: $id)
        }
      `;
    }

    private gql_mutation_deleteFacility() {
        return gql`
        mutation deleteFacility($id: Int){
          deleteFacility(id: $id)
        }
      `;
    }

    private gql_mutation_deleteClient() {
        return gql`
        mutation deleteClient($id: Int){
          deleteClient(id: $id)
        }
      `;
    }

    private gql_mutation_deleteOrganization() {
        return gql`
        mutation deleteOrganization($id: Int){
          deleteOrganization(id: $id)
        }
      `;
    }

    private gql_query_sensors_under_context() {
      return gql`
        query sensorsUnderContext($id: Int, $contextTypeId: String, $groupByTypeId: String){
          sensorsUnderContext(id: $id, contextTypeId: $contextTypeId, groupByTypeId: $groupByTypeId){
            id
            name
            typeId
            sensors {
              id
              name
            }
          }
        }
      `;
    }

    private gql_querySensorTypes() {
      return gql `
        query sensorTypes{
          sensorTypes{
            id
            name
            subtypes {
              id
              name
            }
          }
        }
      `;
    }

    private gql_query_thingContextPath() {
      return gql `
        query thingContextPath($thingId: Int){
          thingContextPath(thingId: $thingId){
            id
            name
		        typeId
          }
        }
      `;
    }

    private gql_mutation_createDashboardPanel() {
      return gql`
      mutation createDashboardPanel($id: Int, $contextTypeId: String, $panelTypeId: Int, $panelName: String, $config: String) {
        createDashboardPanel(id: $id, contextTypeId: $contextTypeId, panelTypeId: $panelTypeId, panelName: $panelName, config: $config)
      }
      `;
    }

    private gql_mutation_updateDashboardOrganization() {
      return gql`
        mutation updateDashboardOrganization($id: Int, $contextTypeId: String, $config: String){
            updateDashboardOrganization(id: $id, contextTypeId: $contextTypeId, config: $config)
        }
      `;
    }

    private gql_mutation_deleteDashboardPanel() {
      return gql`
        mutation deleteDashboardPanel($id: Int, $contextTypeId: String, $panelId: Int) {
            deleteDashboardPanel(id: $id, contextTypeId: $contextTypeId, panelId: $panelId)
        }
      `;
    }

    private gql_query_thingsUnderContext() {
      return gql`
        query thingsUnderContext($id: Int, $contextTypeId: String){
          thingsUnderContext(id: $id, contextTypeId: $contextTypeId){
            id
            parentId
            name
          }
        }
      `;
    }

    private gql_mutation_createAlarm() {
      return gql`
        mutation createAlarm($name: String, $description: String, $thingId: Int, $occurrences: Int, $activationStatusTypeId: Int, $aggregationTypeId: Int, $levelTypeId: Int, $autoCloseable: Int, $rules: [AlarmRules], $subscribers: [AlarmSubscribers]) {
            createAlarm(name: $name, description: $description, thingId: $thingId, occurrences: $occurrences, activationStatusTypeId: $activationStatusTypeId, aggregationTypeId: $aggregationTypeId, levelTypeId: $levelTypeId, autoCloseable: $autoCloseable, rules: $rules, subscribers: $subscribers)
            { id,
              created
            }
        }
      `;
    }
 
    private gql_query_explorerDownload() {
      return gql`
        query explorerDownload($sensors: [String], $from: String, $to: String, $aggregation: String, $group: String, $ignoreSeconds: Boolean, $fileTypeId: String, $minVal: Float, $maxVal: Float){
          explorerDownload(sensors: $sensors, from: $from, to: $to, aggregation: $aggregation, group: $group, ignoreSeconds: $ignoreSeconds, fileTypeId: $fileTypeId, minVal: $minVal, maxVal: $maxVal){
            payload
          }
        }
      `;
    }

    private gql_mutation_updateDashboardPanel() {
      return gql`
        mutation updateDashboardPanel ($id: Int, $contextTypeId: String, $panelTypeId: Int, $panelId: Int, $panelName: String, $config: String) {
          updateDashboardPanel (id: $id, contextTypeId: $contextTypeId, panelTypeId: $panelTypeId, panelId: $panelId, panelName: $panelName, config: $config)
        }
      `;
    }

    private gql_mutation_updateAlarm() {
      return gql`
        mutation updateAlarm($id: Int, $name: String, $description: String, $occurrences: Int, $aggregationTypeId: Int, $levelTypeId: Int, $autoCloseable: Boolean){
            updateAlarm(id: $id, name: $name, description: $description, occurrences: $occurrences, aggregationTypeId: $aggregationTypeId, levelTypeId: $levelTypeId, autoCloseable: $autoCloseable)
        }
      `;
    }

    private gql_query_sensor_formulas() {
        return gql`
            query sensorFormulas($id: String, $outputId: String){
              sensorFormulas(id: $id, outputId: $outputId){
                expression
                timespan
              }
            }
        `;
    }

    private gql_mutation_updateFormula() {
      return gql`
        mutation updateSensorFormula($id: String, $outputId: String, $formula: String, $timespan: Int){
          updateSensorFormula(id: $id, outputId: $outputId, formula: $formula, timespan: $timespan)
        }
      `;
    }

    private gql_mutation_createFormula() {
        return gql`
        mutation createSensorFormula($id: String, $outputId: String, $formula: String, $timespan: Int){
          createSensorFormula(id: $id, outputId: $outputId, formula: $formula, timespan: $timespan)
        }
      `;
    }

    private gql_query_usersByProfile() {
      return gql`
        query usersByProfile($id: Int) {
          usersByProfile(id: $id) {
            id
            name
            position
            userContactList {
              cid
              label
              value
              contactTypeId
              contactTypeName
            }
          }
        }
      `;
    }


    private gql_query_sensor_audit_log() {
      return gql`
        query sensorAuditLog($sensorId: String, $from: String, $to: String) {
          sensorAuditLog(sensorId: $sensorId, from: $from, to: $to){
            value
            updatets
            userName
            operation
            comment
            sensorts
          }
        }
      `;
    }

    private gql_mutation_update_organization() {
      return gql`
        mutation updateOrganization(
          $id: Int,
          $name: String,
          $locationDetails: LocationDetails) {
            updateOrganization(
              id: $id,
              name: $name,
              locationDetails: $locationDetails)
        }
      `;
    }

    private gql_query_downlinkQueue() {
      return gql`
      query downlinkQueue($id: Int, $eui: String){
        downlinkQueue(id: $id,eui: $eui) {
            confirmed,
            data,
            devEUI,
            fCnt,
            fPort,
            jsonObject
        }
      }`;
    }

    private gql_query_potentialSubscribersByContext() {
      return gql`
      query potentialSubscribersByContext($id: Int){
        potentialSubscribersByContext(id: $id) {
          name
          id
          contact
          contactId
          contactTypeName
          contactTypeId
        }
      }`;
    }

    private gql_query_application_containers() {
      return gql`
      query applicationContainers($profileId: Int){
        applicationContainers(profileId: $profileId) {
          host
          port
          status
          applicationInstances {
            name
            context
            started
            status
            applicationToken,
            runningListeners
          }
        }
      }`;
    }

    private gql_query_customer_subscriptions_under_context() {
      return gql`
      query customerSubscriptionsUnderContext($profileId: Int){
        customerSubscriptionsUnderContext(profileId: $profileId) {
          id
          profileId
          profileName
          archived
          reference
          startDate
          endDate
          controllerList {
            id
            name
          }
        }
      }`;
    }

    private gql_query_devices_with_customer_subscription() {
      return gql`
      query devicesWithCustomerSubscription($profileId: Int) {
        devicesWithCustomerSubscription(profileId: $profileId) {
            id
            reference
            name
            modelName
            path
            startDate
            endDate
            
        }
      }`;
    }

    private gql_mutation_delete_customer_subscription() {
      return gql`
        mutation deleteCustomerSubscription($id: Int){
          deleteCustomerSubscription(id: $id)
        }
      `;
    }

    private gql_mutation_stop_application() {
      return gql`
        mutation stopApplication($fullHost: String, $applicationToken: String){
          stopApplication(fullHost: $fullHost, applicationToken: $applicationToken)
        }
      `;
    }

    private gql_query_getProfilesUnder_byProfileID() {
      return gql`
      query getProfilesUnderProfileId($profileId: Int) {
        getProfilesUnderProfileId(profileId: $profileId) {
            id
            name
        }
      }`;
    }

    private gql_getSensorControllers_byProfileID_withPath() {
      return gql`
      query getSensorControllersByProfileID_withPath($profileId: Int) {
        getSensorControllersByProfileID_withPath(profileId: $profileId) {
            id
            name
            modelName
            modelId
        }
      }`;
    }

    private gql_query_live_data_configured_applications() {
      return gql`
      query liveDataConfiguredApplications($profileId: Int){
        liveDataConfiguredApplications(profileId: $profileId) {
          name
          context
        }
      }`;
    }

    private gql_query_user_section_permitions() {
      return gql`
      query getUserSectionPermissions($id: Int, $sectionId: Int){
        getUserSectionPermissions(id: $id, sectionId: $sectionId) {
          sectionId
          read
          create
          update
          delete
        }
      }`;
    }

    private gql_mutation_deleteUser() {
      return gql`
        mutation deleteUser($id: Int){
          deleteUser(id: $id)
        }
      `;
    }

    private gql_mutation_update_user() {
      return gql`
        mutation updateUser($id: Int, $name: String, $position: String, $jsonContacts: String){
          updateUser(id: $id, name: $name, position: $position, jsonContacts: $jsonContacts)
        }
      `;
    }

    private gql_mutation_updateCommunicationCheck() {
      return gql`
        mutation updateCommunicationCheck($id: Int, $monitored: Int, $timeout: Int){
          updateCommunicationCheck(id: $id, monitored: $monitored, timeout: $timeout)
        }
      `;
    }

    private gql_query_panelsUnderByProfileId() {
      return gql`
        query panelsUnderByProfileId($id: Int) {
          panelsUnderByProfileId(id: $id) {
              id,
              name,
              typeId,
              entityId,
              entityLabel,
              width,
              height,
              properties {
                key
                value
              }
            }
        }`;
  }

  private gql_query_getSensorControllerIdAlreadyExists() {
    return gql`
      query getSensorControllerIdAlreadyExists($serialId: Int){
        getSensorControllerIdAlreadyExists(serialId: $serialId) 
      }`;
  }

  private gql_query_getServiceTasksByThingId() {
    return gql`
      query getServiceTasksByThingId($id: Int){
        getServiceTasksByThingId(id: $id) {
          id,
          entityId,
          serviceTypeId,
          profileId,
          startDate,
          endDate,
          reference,
          json,
          userName,
          thingLabel
        }
      }`;
  }

  private gql_query_getServiceTasksByProfileId() {
    return gql`
      query getServiceTasksByProfileId($id: Int){
        getServiceTasksByProfileId(id: $id) {
          id,
          entityId,
          serviceTypeId,
          profileId,
          startDate,
          endDate,
          reference,
          json,
          userName,
          thingLabel
        }
      }`;
  }

  private gql_query_getReportTypes() {
    return gql`
      query getReportTypes {
        getReportTypes {
          id,
          name,
          properties
        }
      }`;
  }

    updateOrganization(
      id: number,
      name: string,
      locationDetails: any,
      cb: any) {
      this.loading = true;
      this.apollo.mutate({
          mutation: this.gql_mutation_update_organization(),
          variables: {
            id,
            name,
            locationDetails
          }
      }).subscribe((response) => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      });
    }

    private gql_mutation_update_client() {
      return gql`
        mutation updateClient ($id: Int,$name: String, $locationDetails: LocationDetails) {
          updateClient (id: $id, name: $name, locationDetails: $locationDetails)
        }
      `;
    }

    updateUser(id: number, name: string, position: string, jsonContacts: any, cb: any) {
      this.loading = true;
      this.apollo.mutate({
          mutation: this.gql_mutation_update_user(),
          variables: {
            id,
            name,
            position,
            jsonContacts: JSON.stringify(jsonContacts)
          }
      }).subscribe((response) => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      });
    }

    updateClient(
      id: number,
      name: string,
      locationDetails: any,
      cb: any) {
      this.loading = true;
      this.apollo.mutate({
          mutation: this.gql_mutation_update_client(),
          variables: {
            id,
            name,
            locationDetails
          }
      }).subscribe((response) => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      });
    }

    private gql_mutation_update_facility() {
      return gql`
        mutation updateFacility ($id: Int,$name: String, $locationDetails: LocationDetails) {
          updateFacility (id: $id, name: $name, locationDetails: $locationDetails)
        }
      `;
    }

    updateFacility(
      id: number,
      name: string,
      locationDetails: any,
      cb: any) {
      this.loading = true;
      this.apollo.mutate({
          mutation: this.gql_mutation_update_facility(),
          variables: {
            id,
            name,
            locationDetails
          }
      }).subscribe((response) => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      });
    }

    private gql_mutation_update_thing() {
      return gql`
        mutation updateThing(
          $id: Int,
          $name: String,
          $locationDetails: LocationDetails) {
            updateThing(
              id: $id,
              name: $name,
              locationDetails: $locationDetails)
        }
      `;
    }

    updateThing(
      id: number,
      name: string,
      locationDetails: any,
      cb: any) {
      this.loading = true;
      this.apollo.mutate({
          mutation: this.gql_mutation_update_thing(),
          variables: {
            id,
            name,
            locationDetails
          }
      }).subscribe((response) => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      });
    }

    private gql_mutation_update_device(){
      return gql`
        mutation updateDevice($id: Int, $name: String, $config: String){
          updateDevice(id: $id, name: $name, config: $config)
        }
      `;
    }

    updateDevice(id: number, name: string, config: string, cb: any) {
      this.loading = true;
      this.apollo.mutate({
          mutation: this.gql_mutation_update_device(),
          variables: {
              id,
              name,
              config
          }
      }).subscribe((response) => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      });
    }

    private gql_mutation_update_sensor_controller_lora_ota(){
      return gql`
        mutation updateSensorControllerLoraOTA($id: Int, $name: String, $appskey: String){
          updateSensorControllerLoraOTA(id: $id, name: $name, appskey: $appskey)
        }
      `;
    }

    updateSensorControllerLoraOTA(id: number, name: string, appskey: string, cb: any) {
      this.loading = true;
      this.apollo.mutate({
          mutation: this.gql_mutation_update_sensor_controller_lora_ota(),
          variables: {
              id,
              name,
              appskey
          }
      }).subscribe((response) => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      });
    }

    private gql_mutation_update_sensor_controller_lora_abp(){
      return gql`
        mutation updateSensorControllerLoraABP($id: Int, $name: String, $appskey: String, $nwskey: String, $devaddr: String){
          updateSensorControllerLoraABP(id: $id, name: $name, appskey: $appskey, nwskey: $nwskey, devaddr: $devaddr)
        }
      `;
    }

    updateSensorControllerLoraABP(id: number, name: string, appskey: string,  nwskey: string, devaddr: string, cb: any) {
      this.loading = true;
      this.apollo.mutate({
          mutation: this.gql_mutation_update_sensor_controller_lora_abp(),
          variables: {
              id,
              name,
              appskey,
              nwskey,
              devaddr
          }
      }).subscribe((response) => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      });
    }

    private gql_mutation_update_sensor(){
      return gql`
        mutation updateSensor(
          $id: Int,
          $name: String,
          $relativeId: Int,
          $sensorSubTypeId: Int
          ) {
          updateSensor(
            id: $id,
            name: $name,
            relativeId: $relativeId,
            sensorSubTypeId: $sensorSubTypeId
          )
        }
      `;
    }

    updateSensor(id: number, name: string, relativeId: number, sensorSubTypeId: number, cb: any) {
      this.loading = true;
      this.apollo.mutate({
          mutation: this.gql_mutation_update_sensor(),
          variables: {
              id,
              name,
              relativeId,
              sensorSubTypeId
          }
      }).subscribe((response) => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      });
    }



    private gql_query_sensorInputTypes(){
      return gql`
        query sensorInputTypes($id: Int){
          sensorInputTypes(id: $id){
            id
            name
          }
        }
      `;
    }


    queryLocation(id: number, contextTypeId: string, cb: (r: any) => void) {
      this.loading = true;
      this.apollo.query({
          query: this.gql_query_location(),
          variables: {
              id,
              contextTypeId
          }
      }).subscribe((response) => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      });
    }

    updateReport(id: string, data: string, cb: any) {
      this.loading = true;
      this.apollo.mutate({
          mutation: this.gql_mutation_updateReport(),
          variables: {
              id,
              data
          }
      }).subscribe((response) => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      });
    }

    queryReportsUnderContextPaged(ctxId: number, idType: string,  pageNumber: number, cb: any, under?: boolean) {
        this.loading = true;
        this.apollo.query({
            query: this.gql_query_reports_under_context_paged(),
            variables: {
                id: ctxId,
                parentType: idType,
                under: !!under,
                pageNumber: pageNumber,
                entriesPerPage: 30
            }
        })
        .subscribe((response) => {
            this.loading = false;
            if (cb) {
                cb(response);
            }
        });
    }

    queryReports(ctxId: number, idType: string, cb: any) {
      this.loading = true;
      this.apollo.query({
          query: this.gql_query_reports(),
          variables: {
              parentId: ctxId,
              parentType: idType
          }
      })
      .subscribe((response) => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      });
    }

  queryCurrentContext(contextId: number, contextTypeId: string, cb: any) {
      this.loading = true;
      contextTypeId = this.convertTypeIdToLabel(contextTypeId);

      this.apollo.query({
          query: this.gql_query_current_context(),
          variables: {
              id: contextId,
              contextTypeId: contextTypeId
          }
      }).subscribe((response => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      }));
  }

  queryContextTree(contextId: number, contextTypeId: string, cb: any) {
        this.loading = true;
        contextTypeId = this.convertTypeIdToLabel(contextTypeId);

        this.apollo.query({
            query: this.gql_query_context_tree(),
            variables: {
                id: contextId,
                contextTypeId: contextTypeId
            }
        }).subscribe((response => {
            this.loading = false;
            if (cb) {
                cb(response);
            }
        }));
    }

  queryDashboard(contextId: number, contextTypeId: string, cb: (r: any) => void) {
      this.loading = true;
      this.apollo.query({
          query: this.gql_query_dashboard(),
          variables: {
              id: contextId,
              contextTypeId: contextTypeId
          }
      }).subscribe((response) => {
          this.loading = false;
          if (response.data['dashboard'].panels && response.data['dashboard'].panels.length > 0) {
              if (cb) {
                  cb(response);
              }
          } else {
              this.loading = true;
              this.apollo.query({
                  query: this.gql_query_statistics_under_context(),
                  variables: {
                      id: contextId,
                      contextTypeId: contextTypeId
                  }
              }).subscribe((statsResponse) => {
                  this.loading = false;
                  if (cb) {
                      cb(statsResponse);
                  }
              });
          }
      });
  }

    queryValuesAdvanced(sensorId: string,
                        from,
                        to,
                        groupBy,
                        groupByValue,
                        andGroupBy,
                        andGroupByValue,
                        operation,
                        cb: (r: any) => void) {
        this.loading = true;
        if (!from) { from = ''; }
        if (!to) { to = ''; }
        if (!groupBy) { groupBy = ''; }
        if (!groupByValue) { groupByValue = ''; }
        if (!andGroupBy) { andGroupBy = ''; }
        if (!andGroupByValue) { andGroupByValue = ''; }
        if (!operation) { operation = ''; }

        this.apollo.query({
            query: this.gql_query_values_advanced(),
            variables: {
                id: sensorId,
                from: from,
                to: to,
                aggregation: operation,
                group: groupBy,
                groupValue: groupByValue,
                andGroup: andGroupBy,
                andGroupValue: andGroupByValue
            }
        }).subscribe((response) => {
            this.loading = false;
            if (cb) {
                cb(response);
            }
        });
    }

  queryValues(sensorId: string, from, to, groupBy, operation, limit, minVal, maxVal, cb: (r: any) => void) {
      this.loading = true;
      if (!from) { from = ''; }
      if (!to) { to = ''; }
      if (!groupBy) { groupBy = ''; }
      if (!operation) { operation = ''; }
      if (!limit) { limit = ''; }

      this.apollo.query({
          query: this.gql_query_values(),
          variables: {
              id: sensorId,
              from: from,
              to: to,
              aggregation: operation,
              group: groupBy,
              limit: limit,
              minVal: minVal,
              maxVal: maxVal
          }
      }).subscribe((response) => {
          this.loading = false;
              if (cb) {
                  cb(response);
              }
      });
  }

    watchQueryValuesDetailed(sensorId: string, from, to, groupBy, operation, limit, cb: (r: any) => void) {
      this.loading = true;
      if (!from) { from = ''; }
      if (!to) { to = ''; }
      if (!groupBy) { groupBy = ''; }
      if (!operation) { operation = ''; }
      if (!limit) { limit = ''; }


      return this.apollo.subscribe({
        query: this.gql_query_values(),
          variables: {
              id: sensorId,
              from: from,
              to: to,
              aggregation: operation,
              group: groupBy,
              limit: limit
          }
      });
  }

  watchQueryValues(sensorId: string) {
      return this.apollo.subscribe({
          query: this.gql_subscribe_values(),
          variables: {
              sensorId
          }
      });
  }

  documentDownload(url: string, cb: (r:any) => void) {
      this.loading = true;
      this.apollo.query({
          query: this.gql_query_document_download(),
          variables: {
              url
          }
      }).subscribe((response) => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      });
  }

    queryDocumentsUnderContext(contextId: number, cb: (r: any) => void) {
      this.loading = true;
      this.apollo.query({
          query: this.gql_query_documents_under_context(),
          variables: {
            id: contextId
          }
      })
          .subscribe((response) => {
              this.loading = false;
              if (cb) {
                  cb(response);
              }
          })
    }

    queryAlarmsDiagnosticUnderContext(contextId: number, contextTypeId: string, cb: (r: any) => void) {
      this.loading = true;
      contextTypeId = this.convertTypeIdToLabel(contextTypeId);

      this.apollo.query({
          query: this.gql_query_alarms_diagnostic_under_context(),
          variables: {
              id: contextId,
              contextTypeId: contextTypeId
          }
      })
      .subscribe((response) => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      });
  }

    queryAlarmsUnderContext(contextId: number, contextTypeId: string, cb: (r: any) => void) {
        this.loading = true;
        contextTypeId = this.convertTypeIdToLabel(contextTypeId);

        this.apollo.query({
            query: this.gql_query_alarms_under_context(),
            variables: {
                id: contextId,
                contextTypeId: contextTypeId
            }
        })
        .subscribe((response) => {
            this.loading = false;
            if (cb) {
                cb(response);
            }
        });
    }

    deleteAlarmSubscriber(id: number, contactId: number, contactTypeId: number, cb: (r: any) => void) {
        this.loading = true;
        this.apollo.mutate({
            mutation: this.gql_mutation_deleteAlarmSubscriber(),
            variables: {
                id,
                contactId,
                contactTypeId
            }
        }).subscribe((response) => {
            this.loading = false;
            if (cb) {
                cb(response);
            }
        });
    }

    deleteAlarm(id: number, cb: (r: any) => void) {
      this.loading = true;
      this.apollo.mutate({
          mutation: this.gql_mutation_deleteAlarm(),
          variables: {
              id
          }
      }).subscribe((response) => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      });
  }

    createAlarmSubscriber(id: number, contactId: number, contactTypeId: number, cb: (r: any) => void) {
        this.loading = true;
        this.apollo.mutate({
            mutation: this.gql_mutation_createAlarmSubscriber(),
            variables: {
                id,
                contactId,
                contactTypeId
            }
        }).subscribe((response) => {
            this.loading = false;
            if (cb) {
                cb(response.data['createAlarmSubscriber']);
            }
        });
    }

    createAlarmSubscribers(id: number, subscribers: any[], cb: (r: any) => void) {
      this.loading = true;
      this.apollo.mutate({
          mutation: this.gql_mutation_createAlarmSubscribers(),
          variables: {
              id,
              subscribers
          }
      }).subscribe((response) => {
          this.loading = false;
          if (cb) {
              cb(response.data['createAlarmSubscribers']);
          }
      });
  }

  querySensorsByThing(thingId: number): Observable<any> {
    this.loading = true;
    return this.apollo.query({
      query: this.gql_query_sensorsByThing(),
      variables: {
        id: thingId
      }
    }).pipe(
      tap(() => {
        this.loading = false;
      }),
      catchError(error => {
        this.loading = false;
        console.error(error);
        return throwError(() => new Error('An error occurred'));
      })
    );
  }
  

    queryTypes(cb: (r: any) => void) {
      this.apollo.query({
          query: this.gql_query_types()
      }).subscribe((response) => {
          if (cb) {
              cb(response);
          }
      });
    }

    queryUsers(thingId: number, cb: (r: any) => void) {
        this.apollo.query({
            query: this.gql_query_users_by_thing(),
            variables: {
                id: thingId
            }
        }).subscribe((response) => {
            if (cb) {
                cb(response);
            }
        });
    }

    queryAlarmHistory(id: number, cb: (r: any) => void) {
      this.loading = true;
      this.apollo.query({
          query: this.gql_query_alarmHistory(),
          variables: {
              id
          }
      }).subscribe((response) => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      });
    }

    createAlarmRule(id: number, operatorTypeId: number, value: number, sensorId: number, startTime: string, endTime: string, cb: (r: any) => void) {
        this.loading = true;
        this.apollo.mutate({
            mutation: this.gql_createAlarmRule(),
            variables: {
                id,
                operatorTypeId,
                value,
                sensorId,
                startTime,
                endTime
            }
        }).subscribe((response) => {
            this.loading = false;
            if (cb) {
                cb(response['createAlarmRule']);
            }
        });
    }

    updateAlarmRule(id: number, alarmRuleId: number, operatorTypeId: number, value: number, startTime: string, endTime: string, cb: (r: any) => void) {
        this.loading = true;
        this.apollo.mutate({
            mutation: this.gql_updateAlarmRule(),
            variables: {
                id,
                alarmRuleId,
                operatorTypeId,
                value,
                startTime,
                endTime
            }
        }).subscribe((response) => {
            this.loading = false;
            if (cb) {
                cb(response['updateAlarmRule']);
            }
        });
    }

    deleteReport(id: string, cb: (r: any) => void) {
      this.loading = true;
      this.apollo.mutate({
          mutation: this.gql_mutation_deleteReport(),
          variables: {
              id
          }
      }).subscribe((response) => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      });
    }

    deleteAlarmRule(id: number, ruleId: number, cb: (r: any) => void) {
      this.loading = true;
      this.apollo.mutate({
          mutation: this.gql_deleteAlarmRule(),
          variables: {
              id,
              ruleId
          }
      }).subscribe((response) => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      });
    }

    updateAlarmStatus(id: number, activationStatusTypeId: number, cb: (r: any) => void) {
      this.loading = true;
      this.apollo.mutate({
          mutation: this.gql_updateAlarmStatus(),
          variables: {
              id,
              activationStatusTypeId
          }
      }).subscribe((response) => {
         this.loading = false;
         if (cb) {
             cb(response);
         }
      });
    }

    login(username: string, password: string, cb: (r: any) => void) {
      this.loading = true;
      this.apollo.query({
          query: this.gql_rest_login(),
          variables: {
              username,
              password
          }
      }).subscribe(response => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      });
    }

    ssoLogin(email: string, cb: (r: any) => void) {
      this.loading = true;
      this.apollo.query({
          query: this.gql_rest_sso_login(),
          variables: {
              email
          }
      }).subscribe(response => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      });
    }

    // this is used after the SSO Login is done, so passing the sso login token and receiving back the livense token, context id and permissions
    ssoAuthenticate(ssoToken: string, cb: (r: any) => void) {
      this.loading = true;
      this.apollo.query({
          query: this.gql_rest_ssoAuthenticate(),
          variables: {
              token: ssoToken
          }
      }).subscribe(response => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      });
    }

    queryDelivery(id: number, idType: string, cb: (r: any) => void) {
        this.apollo.query({
            query: this.gql_queryDelivery(),
            variables: {
                id,
                idType
            }
        })
            .subscribe((response) => {
                if (cb) {
                    cb(response);
                }
            });
    }

    postDelivery(id: number, idType: string, report: any, cb: (r: any) => void) {
        this.loading = true;
        let reportTemplate = report;
        reportTemplate.form.components.forEach(c => {
            const scstr = [];
            c.components.forEach(sc => {
                scstr.push(JSON.stringify(sc));
            });
            c.components = scstr;
        });

        reportTemplate.data = JSON.stringify(reportTemplate.data);
        reportTemplate = JSON.stringify(reportTemplate);

        this.apollo.mutate({
            mutation: this.gql_postDelivery(),
            variables: {
                id,
                idType,
                report: reportTemplate
            }
        })
        .subscribe((response) => {
            this.loading = false;
            if (cb) {
                cb(response);
            }
        });
    }

    querySensorDetails(id: number, cb: (r: any) => void) {
        this.loading = true;
        this.apollo.query({
            query: this.gql_query_sensor_details(),
            variables: { id }
        })
        .subscribe((response) => {
            this.loading = false;
            if (cb) {
                cb(response);
            }
        });
    }

    querySensorTypes(cb: (r: any) => void) {
        this.loading = true;
        this.apollo.query({
            query: this.gql_querySensorTypes()
        })
        .subscribe((response) => {
            this.loading = false;
            if (cb) {
                cb(response);
            }
        });
    }

    queryDeviceDetails(id: number, cb: (r: any) => void) {
        this.loading = true;
        this.apollo.query({
            query: this.gql_queryDeviceDetails(),
            variables: { id }
        })
            .subscribe((response: any) => {
                this.loading = false;
                if (cb) {
                    /*
                     * TODO: TO BE ENFORCED BY THE SERVICE so we can delete this.
                     */
                    if (response.data && response.data.deviceDetails) {
                        if (response.data.deviceDetails.modelTypeName === 'LoraWan Gateway') {
                            response.data.deviceDetails.serial = '0x' + Number(response.data.deviceDetails.serial).toString(16);
                        }
                    }
                    cb(response);
                }
            });
    }

    queryThingDetails(thingId: number, cb: (r: any) => void) {
        this.loading = true;
        this.apollo.query({
            query: this.gql_queryThingDetails(),
            variables: { id: thingId }
        })
            .subscribe((response) => {
                this.loading = false;
                if (cb) {
                    cb(response);
                }
            });
    }

    queryFacilityDetails(id: number, cb: (r: any) => void) {
        this.loading = true;
        this.apollo.query({
            query: this.gql_queryFacilityDetails(),
            variables: { id }
        })
            .subscribe((response) => {
                this.loading = false;
                if (cb) {
                    cb(response);
                }
            });
    }

    queryClientDetails(id: number, cb: (r: any) => void) {
        this.loading = true;
        this.apollo.query({
            query: this.gql_queryClientDetails(),
            variables: { id }
        })
            .subscribe((response) => {
                this.loading = false;
                if (cb) {
                    cb(response);
                }
            });
    }

    queryOrganizationDetails(id: number, cb: (r: any) => void) {
        this.loading = true;
        this.apollo.query({
            query: this.gql_queryOrganizationDetails(),
            variables: { id }
        })
            .subscribe((response) => {
                this.loading = false;
                if (cb) {
                    cb(response);
                }
            });
    }

    queryDeviceModels(id: any, cb: (response) => void) {
        this.loading = true;
        this.apollo.query({
            query: this.gql_queryDeviceModels(),
            variables: { id }
        })
        .subscribe((response) => {
            this.loading = false;
            if (cb) {
                cb(response);
            }
        });
    }

    queryDeviceLoraWANProfiles(applicationId: number, cb: (response) => any) {
        this.loading = true;
        this.apollo.query({
            query: this.gql_queryDeviceLoraWANProfiles(),
            variables: { applicationId }
        })
            .subscribe((response) => {
                this.loading = false;
                if (cb) {
                    cb(response);
                }
            });
    }

    queryApplicationsByContext(ctx: string, cb: (response) => any) {
        this.loading = true;
        this.apollo.query({
                query: this.gql_queryApplicationsByContext(),
                variables: { ctx }
            })
            .subscribe((response) => {
                this.loading = false;
                if (cb) {
                    cb(response);
                }
            });
    }

    potentialSubscribersByContext(contextId: number, cb: (response) => void) {

      if(this.dataService.getCurrentCtxIdType() && this.dataService.TYPE_THING) {
        contextId = this.dataService.getParentContext().id;
      }

      let profileId = contextId;
      this.loading = true;
      this.apollo.query({
        query: this.gql_query_potentialSubscribersByContext(),
        variables: { id: profileId }
      }).subscribe((response) => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      });
    }

    applicationContainers(profileId: number, cb: (response) => void) {
      this.loading = true;
      this.apollo.query({
        query: this.gql_query_application_containers(),
        variables: { profileId }
      }).subscribe((response) => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      });
    }

    customerSubscriptionsUnderContext(profileId: number, cb: (response) => void) {
      this.loading = true;
      this.apollo.query({
        query: this.gql_query_customer_subscriptions_under_context(),
        variables: { profileId }
      }).subscribe((response) => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      });
    }

    devicesWithCustomerSubscription(profileId: number, cb: (response) => void) {
      this.loading = true;
      this.apollo.query({
        query: this.gql_query_devices_with_customer_subscription(),
        variables: { profileId }
      }).subscribe((response) => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      });
    }

    deleteCustomerSubscription(id: number, cb: (response) => void) {
      this.loading = true;
      this.apollo.query({
        query: this.gql_mutation_delete_customer_subscription(),
        variables: { id }
      }).subscribe((response) => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      });
    }

    stopApplication(fullHost: string, applicationToken: string, cb: (response) => void) {
      this.loading = true;
      this.apollo.query({
        query: this.gql_mutation_stop_application(),
        variables: { fullHost, applicationToken }
      }).subscribe((response) => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      });
    }

    getProfilesUnderProfileId(profileId: number, cb: (response) => void) {
      this.loading = true;
      this.apollo.query({
        query: this.gql_query_getProfilesUnder_byProfileID(),
        variables: { profileId }
      }).subscribe((response) => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      });
    }

    getSensorControllersByProfileID_withPath(profileId: number, cb: (response) => void) {
      this.loading = true;
      this.apollo.query({
        query: this.gql_getSensorControllers_byProfileID_withPath(),
        variables: { profileId }
      }).subscribe((response) => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      });
    }

    createCustomerSubscription(profileId: number, reference: string, startDate: string, endDate: string, devices: any[], cb: (response) => any) {
      this.loading = true;
      this.apollo.mutate({
          mutation: this.gql_mutation_createCustomerSubscription(),
          variables: {
              profileId,
              reference,
              startDate,
              endDate,
              devices
          }
      }).subscribe((response) => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      });
    }

    updateCustomerSubscription(subscriptionId: number, reference: string, startDate: string, endDate: string, devices: any[], cb: (response) => any) {
      this.loading = true;
      this.apollo.mutate({
          mutation: this.gql_mutation_updateCustomerSubscription(),
          variables: {
              id: subscriptionId,
              reference,
              startDate,
              endDate,
              devices
          }
      }).subscribe((response) => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      });
    }

    liveDataConfiguredApplications(profileId: number, cb: (response) => void) {
      this.loading = true;
      this.apollo.query({
        query: this.gql_query_live_data_configured_applications(),
        variables: { profileId }
      }).subscribe((response) => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      });
    }

    liveDataStartApplication(host: string, port: string, context: string, application: string, applicationManagerToken: string, cb: (response) => any) {
      this.loading = true;
      this.apollo.mutate({
          mutation: this.gql_mutation_livedata_start_application(),
          variables: {
              host,
              port,
              context,
              application,
              applicationManagerToken
          }
      }).subscribe((response) => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      });
  }

    createDevice(name: string, serialId: string, modelId: number, thingId: number, config: string, cb: (response) => any) {
        this.loading = true;
        this.apollo.mutate({
            mutation: this.gql_mutation_createDevice(),
            variables: {
                name,
                serialId,
                modelId,
                thingId,
                config
            }
        }).subscribe((response) => {
            this.loading = false;
            if (cb) {
                cb(response);
            }
        });
    }

    createSensor(name: string, serial: string, deviceId: number, inputTypeId: number, subTypeId: number, thingId: number, cb: (response) => any) {
      this.loading = true;
      this.apollo.mutate({
          mutation: this.gql_mutation_createSensor(),
          variables: {
              name,
              serial,
              deviceId,
              inputTypeId,
              subTypeId,
              thingId
          }
      }).subscribe((response) => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      });
    }

    queryLocationsParking(id: string, idType: string, cb: (response) => any) {
      this.loading = true;
      this.apollo.query({
            query: this.gql_query_locations_parking(),
            variables: {
                id,
                idType
            }
        }).subscribe( (response) => {
            this.loading = false;
            if (cb) {
                cb(response);
            }
      });
    }

    queryValuesParking(sensorId: string, from: string, to: string, limit: string, cb: (response) => any) {
      this.loading = true;
      if (!from) {
          from = '';
      }
      if (!to) {
          to = '';
      }
      if (!limit) {
          limit = '';
      }
      this.apollo.query({
          query: this.gql_query_values_parking(),
          variables: {
              id: sensorId,
              from: from,
              to: to,
              limit: limit
          }
      }).subscribe( (response) => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      });
    }

    createAlarmStatusHistory(id: number, statusTypeId: number, comment: string, cb: (response) => any) {
      this.loading = true;
      this.apollo.query({
        query: this.gql_mutation_createAlarmStatusHistory(),
        variables: {
            id,
            statusTypeId,
            comment
        }
      }).subscribe( (response) => {
        this.loading = false;
        if (cb) {
            cb(response);
        }
      });
    }

    queryParkingList(currentCtxId: number, currentCtxIdType: string, cb: (response) => any) {
        this.loading = true;
        this.apollo.query({
            query: this.gql_query_parking_list(),
            variables: {
                id: currentCtxId,
                idType: currentCtxIdType
            }
        })
        .subscribe((response) => {
            this.loading = false;
            if (cb) {
                cb(response);
            }
        });
    }

    queryCountries(cb: (response) => void) {
      this.loading = true;
      this.apollo.query({
          query: this.gql_query_countries(),
          variables: {}
      })
      .subscribe((response) => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      });
    }

    queryCountryStates(countryId: string, cb: (response) => void) {
        this.loading = true;
        this.apollo.query({
            query: this.gql_query_country_states(),
            variables: {
                id: countryId
            }
        })
        .subscribe((response) => {
            this.loading = false;
            if (cb) {
                cb(response);
            }
        });
    }

    createOrganization(name: string, parentId: number, locationDetails: any, cb: (response) => void) {
        this.loading = true;
        this.apollo.mutate({
            mutation: this.gql_mutation_createOrganization(),
            variables: { name, parentId, locationDetails }
        }).subscribe((response) => {
            this.loading = false;
            if (cb) {
                cb(response);
            }
        });
    }

    createClient(name: string, parentId: number, locationDetails: any, cb: (response) => void) {
        this.loading = true;
        this.apollo.mutate({
            mutation: this.gql_mutation_createClient(),
            variables: {
              name,
              parentId,
              locationDetails
            }
        }).subscribe((response) => {
            this.loading = false;
            if (cb) {
                cb(response);
            }
        });
    }

    createFacility(name: String, parentId: number, locationDetails: any, cb: (response) => void) {
        this.loading = true;
        this.apollo.mutate({
            mutation: this.gql_mutation_createFacility(),
            variables: { name, parentId, locationDetails }
        }).subscribe((response) => {
            this.loading = false;
            if (cb) {
                cb(response);
            }
        });
    }

    createThing(name: String, parentId: number, parentType: String, locationDetails: any, cb: (response) => void) {
        this.loading = true;
        this.apollo.mutate({
            mutation: this.gql_mutation_createThing(),
            variables: { name, parentId, parentType, locationDetails }
        }).subscribe((response) => {
            this.loading = false;
            if (cb) {
                cb(response);
            }
        });
    }

    stop() {
      this.apollo.getClient().stop();
      (window as any).wslink.subscriptionClient.close(true, true);
    }

    deleteSensor(id: number, cb: (response) => void){
      this.loading = true;
      this.apollo.mutate({
          mutation: this.gql_mutation_deleteSensor(),
          variables: { id }
      }).subscribe((response) => {
          this.loading = false;
          if(cb) {
              cb(response);
          }
      });
    }

    deleteController(id: number, cb: (response) => void){
        this.loading = true;
        this.apollo.mutate({
            mutation: this.gql_mutation_deleteController(),
            variables: { id }
        }).subscribe((response) => {
            this.loading = false;
            if(cb) {
                cb(response);
            }
        });
    }

    deleteThing(id: number, cb: (response) => void){
        this.loading = true;
        this.apollo.mutate({
            mutation: this.gql_mutation_deleteThing(),
            variables: { id }
        }).subscribe((response) => {
            this.loading = false;
            if(cb) {
                cb(response);
            }
        });
    }

    deleteFacility(id: number, cb: (response) => void){
        this.loading = true;
        this.apollo.mutate({
            mutation: this.gql_mutation_deleteFacility(),
            variables: { id }
        }).subscribe((response) => {
            this.loading = false;
            if(cb) {
                cb(response);
            }
        });
    }

    deleteClient(id: number, cb: (response) => void){
        this.loading = true;
        this.apollo.mutate({
            mutation: this.gql_mutation_deleteClient(),
            variables: { id }
        }).subscribe((response) => {
            this.loading = false;
            if(cb) {
                cb(response);
            }
        });
    }

    deleteOrganization(id: number, cb: (response) => void){
        this.loading = true;
        this.apollo.mutate({
            mutation: this.gql_mutation_deleteOrganization(),
            variables: { id }
        }).subscribe((response) => {
            this.loading = false;
            if(cb) {
                cb(response);
            }
        });
    }

    querySensorsUnderContext(id: number, contextTypeId: string, groupByTypeId: string, cb: (response) => any) {
        this.loading = true;
        this.apollo.query({
            query: this.gql_query_sensors_under_context(),
            variables: { id, contextTypeId, groupByTypeId }
        }).subscribe((response) => {
            this.loading = false;
            if (cb) {
                cb(response);
            }
        });
    }

    createDashboardPanel(id: number, contextTypeId: string, panelTypeId: number, panelName: any, json: any, cb: (response) => void) {
        this.loading = true;
        this.apollo.mutate({
            mutation: this.gql_mutation_createDashboardPanel(),
            variables: { id, contextTypeId, panelTypeId, panelName, config: JSON.stringify(json) }
        }).subscribe((response) => {
            this.loading = false;
            if (cb) {
                cb(response);
            }
        });
    }

    updateDashboardPanel(id: number, contextTypeId: string, panelTypeId: number, panelId: number, panelName: string, json: any, cb: (response) => void) {
      this.loading = true;
      this.apollo.mutate({
          mutation: this.gql_mutation_updateDashboardPanel(),
          variables: { id, contextTypeId, panelTypeId, panelId, panelName, config: JSON.stringify(json)}
      }).subscribe((response) => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      });
    }

    updateDashboardOrganization(id: number, contextTypeId: string, json: any, cb: (response) => void){
        this.loading = true;
        this.apollo.mutate({
            mutation: this.gql_mutation_updateDashboardOrganization(),
            variables: { id, contextTypeId, config: JSON.stringify(json) }
        }).subscribe((response) => {
            this.loading = false;
            if (cb) {
                cb(response);
            }
        });
    }

    deleteDashboardPanel(id: number, contextTypeId: string, panelId: any, cb: (response) => void) {
        this.loading = true;
        this.apollo.mutate({
            mutation: this.gql_mutation_deleteDashboardPanel(),
            variables: { id, contextTypeId, panelId }
        }).subscribe((response) => {
            this.loading = false;
            if (cb) {
                cb(response);
            }
        });
    }

    queryThingsByProfile(id: number, contextTypeId: string): Observable<any> {
      this.loading = true;
      return this.apollo.query({
        query: this.gql_query_thingsUnderContext(),
        variables: { id, contextTypeId }
      }).pipe(
        tap(() => {
          this.loading = false;
        }),
        catchError(error => {
          this.loading = false;
          console.error(error);
          return throwError(() => new Error('An error occurred'));
        })
      );
    }
    

    queryFormula(sensorId: string, cb: (response) => void) {
      this.loading = true;
      this.apollo.query({
          query: this.gql_query_sensor_formulas(),
          variables: { id: sensorId, outputId: sensorId }
      }).subscribe((response) => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      });
    }

    createAlarm(title: any, description: any, thingId: number, occurrences: any,
                state: any, aggregation: any, level: any, autocloseable: number, rules: any[], subscribers: any[],
                cb: (response) => void) {
        this.loading = true;
        this.apollo.mutate({
            mutation: this.gql_mutation_createAlarm(),
            variables: {    name: title,
                            description,
                            thingId,
                            occurrences,
                            activationStatusTypeId: state,
                            aggregationTypeId: aggregation,
                            levelTypeId: level,
                            autoCloseable: autocloseable,
                            rules: rules,
                            subscribers: subscribers }
        }).subscribe((response) => {
            this.loading = false;
            if (cb) {
                cb(response);
            }
        });
    }

    explorerDownload(sensors, from, to, aggregation, group, ignoreSeconds,fileTypeId, minVal, maxVal, cb: (r: any) => void) {
        this.loading = true;
        this.apollo.query({
            query: this.gql_query_explorerDownload(),
            variables: { sensors, from, to, aggregation, group, ignoreSeconds, fileTypeId, minVal, maxVal }
        }).subscribe((response) => {
            this.loading = false;
            if (cb) {
                cb(response);
            }
        });
    }

    updateAlarm(id: number, title: any, description: any, occurrences: any, aggregation: any, level: any, autocloseable: number, cb: (response) => void) {
        this.loading = true;
        this.apollo.mutate({
            mutation: this.gql_mutation_updateAlarm(),
            variables: { id: id, name: title, description, occurrences, aggregationTypeId: aggregation, levelTypeId: level, autoCloseable: autocloseable }
        }).subscribe((response) => {
            this.loading = false;
            if (cb) {
                cb(response);
            }
        });
    }

    updateFormula(sensorId, formula, timespan, cb: (response) => void) {
        this.loading = true;
        this.apollo.mutate({
            mutation: this.gql_mutation_updateFormula(),
            variables: { id: sensorId, outputId: sensorId, formula, timespan }
        }).subscribe((response) => {
            this.loading = false;
            if (cb) {
                cb(response);
            }
        });
    }

    createFormula(sensorId, formula, timespan, cb: (response) => void) {
        this.loading = true;
        this.apollo.mutate({
            mutation: this.gql_mutation_createFormula(),
            variables: { id: sensorId, outputId: sensorId, formula, timespan }
        }).subscribe((response) => {
            this.loading = false;
            if (cb) {
                cb(response);
            }
        });
    }

    createVirtualSensorFormula(sensorIdIn, outputId, formula, timespan, cb: (response) => void) {
      this.loading = true;
      this.apollo.mutate({
          mutation: this.gql_mutation_createFormula(),
          variables: { id: sensorIdIn, outputId: outputId, formula, timespan }
      }).subscribe((response) => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      });
  }

    getUsersByProfile(pid, cb: (response) => void) {
      this.loading = true;
      this.apollo.query({
        query: this.gql_query_usersByProfile(),
        variables: {
          id: pid
        }
      }).subscribe((response) => {
        this.loading = false;
        if(cb) {
          cb(response);
        }
      })
    }

    auditLog(sensorId, from, to, cb: (response) => void){
      this.loading = true;
      this.apollo.query({
        query: this.gql_query_sensor_audit_log(),
        variables:{
          sensorId,
          from,
          to
        }
      }).subscribe((response) => {
        this.loading = false;
        if(cb){
          cb(response);
        }
      });
    }

    updateSensorValue(id, timestamp, newvalue, comments, cb: (response) => void) {
      this.loading = true;
      this.apollo.mutate({
        mutation: this.gql_mutation_updateSensorValue(),
        variables: { id: id, timestamp: timestamp, value: newvalue, comments: comments }
      }).subscribe((response) => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      });
    }
    getUserTemplates(pid, cb: (response) => void) {
      this.loading = true;
      this.apollo.query({
        query: this.gql_query_userTemplates(),
        variables: { id: pid }
      }).subscribe((response) => {
        this.loading = false;
        if(cb) {
          cb(response);
        }
      })
    }

    addUser(hostname, name, position, parentId, email, mobile, templateId, cb: (response) => void) {
      this.loading = true;
      this.apollo.mutate({
        mutation: this.gql_mutation_createUser(),
        variables: {hostname: hostname, name: name, position: position, parentId: parentId, email: email, mobile: mobile, templateId: templateId }
      }).subscribe((response) => {
        this.loading = false;
        if(cb) {
          cb(response);
        }
      })
    }

    
    forgotPassword(hostname, email, cb: (response) => void) {
      this.loading = true;
      this.apollo.query({
        query: this.gql_rest_forgotPassword(),
        variables: {hostname: hostname, email: email}
      }).subscribe((response) => {
        this.loading = false;
        if(cb) {
          cb(response);
        }
      })
    }
  

    /*
    forgotPassword(hostname, email, cb: (response) => void) {
      this.loading = true;
      this.apollo.mutate({
        mutation: this.gql_mutation_forgotPassword(),
        variables: {hostname: hostname, email: email}
      }).subscribe((response) => {
        this.loading = false;
        if(cb) {
          cb(response);
        }
      })
    }
    */

    updatePassword(requestId, password, cb: (response) => void) {
      this.loading = true;
      this.apollo.query({
        query: this.gql_rest_updatePassword(),
        variables: { requestId, password }
      }).subscribe((response) => {
        this.loading = false;
        if(cb) {
          cb(response);
        }
      })
    }

    uploadFloorplanImage(image, cb: (response) => void){
      this.loading = true;
      this.apollo.mutate({
        mutation: this.gql_mutation_uploadFloorplanImage(),
        variables: {
          image
        }
      }).subscribe(
        (response) => {
          this.loading = false;
          if(cb) {
            cb(response);
          }
        },
        (error) => {
          this.loading = false;
          console.log(error);
          if(cb) {
            cb(null);
          }
        }
      )
    }

    querySensorInputTypes(id: number, cb: (response) => void) {
      this.loading = true;
      this.apollo.query({
          query: this.gql_query_sensorInputTypes(),
          variables: { id }
      }).subscribe((response) => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      });
    }

    queryDownlinkQueue(id: number, eui: String,  cb: (response) => void) {
      this.loading = true;

      this.apollo.query({
          query: this.gql_query_downlinkQueue(),
          variables: {
              id: id,
              eui: eui
          }
      }).subscribe((response => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      }));
    }

    createDownlink(id: number, eui: String, confirmed: boolean, data: String, fPort: number, cb: (response) => void) {
      this.loading = true;

      this.apollo.query({
          query: this.gql_mutation_createDownlink(),
          variables: {
              id: id,
              eui: eui,
              confirmed: confirmed,
              data: data,
              fPort: fPort
          }
      }).subscribe((response => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      }));
    }

    deleteDownlinkQueue(id: number, eui: String,  cb: (response) => void) {
      this.loading = true;

      this.apollo.query({
          query: this.gql_mutation_deleteDownlinkQueue(),
          variables: {
              id: id,
              eui: eui
          }
      }).subscribe((response => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      }));
    }

    updateThingParent(id: number, parentId: number, cb: (response) => void) {
      this.loading = true;
      this.apollo.mutate({
        mutation: this.gql_mutation_updateThingParent(),
        variables: { id: id, parentId }
      }).subscribe((response) => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      });
    }

    updateUserParent(id: number, parentId: number, cb: (response) => void) {
      this.loading = true;
      this.apollo.mutate({
        mutation: this.gql_mutation_updateUserParent(),
        variables: { id: id, parentId }
      }).subscribe((response) => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      });
    }

    deleteUser(id: number, cb: (response) => void) {
      this.loading = true;

      this.apollo.query({
          query: this.gql_mutation_deleteUser(),
          variables: {
              id: id
          }
      }).subscribe((response => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      }));
    }

    queryThingContextPath(thingId: number,  cb: (response) => void) {
      this.loading = true;

      this.apollo.query({
          query: this.gql_query_thingContextPath(),
          variables: {
            thingId: thingId
          }
      }).subscribe((response => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      }));
    }

    queryPanelsUnderProfileId(profileId: number,  cb: (response) => void) {
      this.loading = true;

      this.apollo.query({
          query: this.gql_query_panelsUnderByProfileId(),
          variables: {
            id: profileId
          }
      }).subscribe((response => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      }));
    }

    getUserSectionPermissions(userId: number, sectionId: number,  cb: (response) => void) {
      this.loading = true;

      this.apollo.query({
          query: this.gql_query_user_section_permitions(),
          variables: {
            id: userId,
            sectionId: sectionId
          }
      }).subscribe((response => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      }));
    }

    updateCommunicationCheck(sensorId, monitored, timeout, cb: (response) => void) {
      this.loading = true;
      this.apollo.mutate({
        mutation: this.gql_mutation_updateCommunicationCheck(),
        variables: { id: sensorId, monitored: monitored, timeout: timeout }
      }).subscribe((response) => {
          this.loading = false;
          if (cb) {
              cb(response);
          }
      });
    }

    // convert two complement decimal (signed decimal) to hexadecimal
    convertTwoComplementDecimalToHex(signedDecimal: any) {

        var size = 8;
        var hexadecimal;

        if (signedDecimal >= 0) {
          var hexadecimal = signedDecimal.toString(16);

          while ((hexadecimal.length % size) != 0) {
            hexadecimal = "" + 0 + hexadecimal;
          }

          return hexadecimal;
        } else {
          hexadecimal = Math.abs(signedDecimal).toString(16);
          while ((hexadecimal.length % size) != 0) {
            hexadecimal = "" + 0 + hexadecimal;
          }

          var output = '';
          for (var i = 0; i < hexadecimal.length; i++) {
            output += (0x0F - parseInt(hexadecimal[i], 16)).toString(16);
          }

          output = (0x01 + parseInt(output, 16)).toString(16);
          return output;
        }


    }

    dec2hex(str) { // .toString(16) only works up to 2^53
      var dec = str.toString().split(''),
         sum = [],
         hex = [],
         i, s
      while (dec.length) {
         s = 1 * dec.shift()
         for (i = 0; s || i < sum.length; i++) {
            s += (sum[i] || 0) * 10
            sum[i] = s % 16
            s = (s - sum[i]) / 16
         }
      }
      while (sum.length) {
         hex.push(sum.pop().toString(16))
      }

      var strHex = hex.join('');


      return strHex;

   }

   decimalStrToHex(bn) {
    bn = BigInt(bn);

    // I've noticed that for some operations BigInts can
    // only be compared to other BigInts (even small ones).
    // However, <, >, and == allow mix and match
    if (bn < 0) {
      bn = this.bitnot(bn);
    }

    var base = 16;
    var hex = bn.toString(base);
    if (hex.length % 2) {
      hex = '0' + hex;
    }
    return hex;
  }

  bitnot(bn) {
    // JavaScript's bitwise not doesn't work on negative BigInts (bn = ~bn; // WRONG!)
    // so we manually implement our own two's compliment (flip bits, add one)
    bn = -bn;
    var bin = (bn).toString(2)
    var prefix = '';
    while (bin.length % 8) {
      bin = '0' + bin;
    }
    if ('1' === bin[0] && -1 !== bin.slice(1).indexOf('1')) {
      prefix = '11111111';
    }
    bin = bin.split('').map(function (i) {
      return '0' === i ? '1' : '0';
    }).join('');
    console.log(BigInt('0b' + prefix + bin) + BigInt(1));
    return BigInt('0b' + prefix + bin) + BigInt(1);
  }

  getSensorControllerIdAlreadyExists(serialId: number, cb: any) {
    this.loading = true;

    this.apollo.query({
        query: this.gql_query_getSensorControllerIdAlreadyExists(),
        variables: {
          serialId: serialId
        }
    }).subscribe((response => {
        this.loading = false;
        if (cb) {
            cb(response);
        }
    }));
  }

  getServiceTasksByThingId(thingId: number,  cb: (response) => void) {
    this.loading = true;

    this.apollo.query({
        query: this.gql_query_getServiceTasksByThingId(),
        variables: {
          id: thingId
        }
    }).subscribe((response => {
        this.loading = false;
        if (cb) {
            cb(response);
        }
    }));
  }

  getServiceTasksByProfileId(profileId: number,  cb: (response) => void) {
    this.loading = true;

    this.apollo.query({
        query: this.gql_query_getServiceTasksByProfileId(),
        variables: {
          id: profileId
        }
    }).subscribe((response => {
        this.loading = false;
        if (cb) {
            cb(response);
        }
    }));
  }

  getReportTypes(): Promise<any> {
    this.loading = true;
  
    return new Promise((resolve, reject) => {
      this.apollo.query({
        query: this.gql_query_getReportTypes(),
        variables: {}
      }).subscribe({
        next: (response) => {
          this.loading = false;
          resolve(response);
        },
        error: (error) => {
          this.loading = false;
          reject(error);
        }
      });
    });
  }
  
  /*
  getReportTypes(cb: (response) => void) {
    this.loading = true;

    this.apollo.query({
        query: this.gql_query_getReportTypes(),
        variables: {}
    }).subscribe((response => {
        this.loading = false;
        if (cb) {
            cb(response);
        }
    }));
  }*/

  createServiceTask(thingId, serviceTypeId, reference, startDate, endDate, jsonStr, cb: (response) => void) {
    this.loading = true;
    this.apollo.mutate({
      mutation: this.gql_mutation_createServiceTask(),
      variables: {
        id: thingId,
        serviceTypeId: serviceTypeId,
        reference: reference,
        startDate: startDate,
        endDate: endDate,
        json: jsonStr
      }
    }).subscribe((response) => {
      this.loading = false;
      if(cb) {
        cb(response);
      }
    })
  }

  updateServiceTask(serviceTaskId, reference, startDate, endDate, jsonStr, cb: (response) => any) {
    this.loading = true;
    this.apollo.mutate({
      mutation: this.gql_mutation_updateServiceTask(),
      variables: {
        id: serviceTaskId,
        reference: reference,
        startDate: startDate,
        endDate: endDate,
        json: jsonStr
      }
    }).subscribe((response) => {
      this.loading = false;
      if(cb) {
        cb(response);
      }
    })
  }

  deleteServiceTask(id: number, cb: (r: any) => void) {
    this.loading = true;
    this.apollo.mutate({
        mutation: this.gql_mutation_deleteServiceTask(),
        variables: {
            id
        }
    }).subscribe((response) => {
        this.loading = false;
        if (cb) {
            cb(response);
        }
    });
  }

}

