import { Component, OnInit } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'app-toast-controller',
  templateUrl: './toast-controller.component.html',
  styleUrls: ['./toast-controller.component.scss'],
})
export class ToastControllerComponent implements OnInit {

    constructor(public toastController: ToastController) { }

    ngOnInit() {}

    async presentToast(msg: string) {
        const toast = await this.toastController.create({
            message: msg,
            duration: 2000,
            position: 'top'
        });
        toast.present();
    }

}
