import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import {AlertController} from '@ionic/angular';
import {Router} from '@angular/router';

@Component({
  selector: 'app-password-forgot',
  templateUrl: './password-forgot.page.html',
  styleUrls: ['./password-forgot.page.scss'],
})
export class PasswordForgotPage implements OnInit {

  recoverClicked = false;

  email = '';
  constructor(public apiService: ApiService, public router: Router) {
    console.log('Constructor Forgot Password');
   }

  ngOnInit() {
    console.log('OnInit Forgot Password');
  }


  async presentAlertConfirm(alertCtrl?: AlertController) {
    this.apiService.loading = false;
    const alertConfig = { 
      header: 'Password',
      message: `An email was sent to reset your password.`,
      buttons: [{
          text: 'Ok',
          handler: () => {
            console.log('Confirm Ok');
          }
        }
      ]
    };

    let alert = null;
    if (alertCtrl) {
      alert = await alertCtrl.create(alertConfig);
    }
    /*
    else {
        alert = await this.alertController.create(alertConfig);
    }
    */

    await alert.present();
  }


  resetPassword(form) {
    this.recoverClicked = true;
    const hostname = window.location.host;

    try {
      this.apiService.forgotPassword(hostname, form.value.email, (responseData) => {
        // TODO: Implement snackbar with message.
        // TODO: Redirect to login if sucessful.
        console.log(responseData);
        this.presentAlertConfirm();
      });
    } catch(e) {
      console.log(e);
      this.presentAlertConfirm();
    }

    setTimeout(() => {
      this.apiService.loading = false;
      this.router.navigateByUrl('login');
    }, 2000);
  }

  onClickBackDefaultLogin() {
    this.router.navigateByUrl('login');
  }

  
}
