import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TreeNodeComponent } from './tree-node/tree-node.component';
import { IonicModule } from '@ionic/angular';
import { FilterPipe } from '../utils/pipes/filter.pipe'; 

@NgModule({
	declarations: [TreeNodeComponent, FilterPipe],
	imports: [IonicModule, CommonModule],
	exports: [TreeNodeComponent, FilterPipe]
})
export class ComponentsModule {}
