<ion-header>
  <ion-toolbar>
    <ion-row>
      <ion-col size="2">
      </ion-col>
      <ion-col>
        <!--
        <ion-title style="text-align: center;" class="ion-no-padding">{{ 'COMPANYNAME' | translate}}</ion-title>
        -->
        <ion-img style="text-align: center; height: 56px; width: auto; padding: 6px" class="ion-no-padding" [src]="'assets/icons/logo_rectangle.png'"></ion-img>
      </ion-col>
      <ion-col size="2" style="text-align: end;">
        <ion-spinner *ngIf="apiService.loading" name="lines"></ion-spinner>
      </ion-col>
    </ion-row>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form #form="ngForm" (ngSubmit)="resetPassword(form)">
    <ion-grid>
      <ion-row color="primary" class="ion-justify-content-center">
        <ion-col align-self-center size="12" size-md="8" size-lg="6" size-xl="4">
          <div>
            <h3 style="text-align: center">{{ 'PAGE_FORGOT.TITLERESETPASSWORD' | translate}}</h3>
          </div>
          <div class="ion-padding">
            <ion-item>
              <ion-input name="email" type="email" placeholder="your@email.com" [ngModel]="email" required></ion-input>
            </ion-item>
          </div>
          <div class="ion-padding">
            <ion-button size="large" type="submit" [disabled]="form.invalid" expand="block">{{ 'PAGE_FORGOT.RESET_PASSWORD' | translate}}</ion-button>
          </div>
          <div class="ion-padding" *ngIf="recoverClicked">
            <ion-label>{{ 'PAGE_FORGOT.EMAIL_SENT' | translate}}</ion-label>
          </div>
          <div class="ion-padding">
            <ion-row *ngIf="true" style="display: flex!important;align-content: center!important; align-items: center!important;">
              <ion-col>
                <ion-label color="primary" (click)="onClickBackDefaultLogin()" style="cursor:pointer">
                  {{ 'PAGE_FORGOT.BACKTOSIGNIN' | translate}}
                </ion-label>
              </ion-col>
            </ion-row>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>
