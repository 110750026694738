<ion-app>

    <ion-menu contentId="context" menuId="context" type="overlay" side="start" [swipeGesture]="true" (ionDidClose)="menuClosed()">
      <ion-header>
        <ion-toolbar>
          <ion-row>
            <ion-col size="10">
              <ion-title>{{'PAGE_COMPONENT.CONTEXT' | translate}}</ion-title>
            </ion-col>
            <!--
            <ion-col (click)="openDebug()" size="2">
              <ion-icon name="barcode" style="zoom:2.0;"></ion-icon>
            </ion-col>
            -->
            <ion-col size="2" (click)="logout()">
              <ion-icon style="zoom: 2.0;" name="log-out"></ion-icon>
            </ion-col>
          </ion-row>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list>
          <div  *ngFor="let level of dataService.context; let idx = index" >
            <ion-item button color="primary" (click)="chooseLevel(level, idx)">
              <ion-icon *ngIf="level.typeId === '501'" slot="start" [name]="'business'"></ion-icon>
              <ion-icon *ngIf="level.typeId === '502'" slot="start" [name]="'home'"></ion-icon>
              <ion-icon *ngIf="level.typeId === '504'" slot="start" [name]="'pin'"></ion-icon>
              <ion-icon *ngIf="level.typeId === '599'" slot="start" [name]="'cube'"></ion-icon>
              <ion-label>
                {{level.name}}
              </ion-label>
            </ion-item>
          </div>

          <div  *ngFor="let level of dataService.contextChildren; let idx = index" >
            <ion-item button (click)="chooseLevel(level, idx)">
              <ion-icon *ngIf="level.typeId === '501'" slot="start" [name]="'business'"></ion-icon>
              <ion-icon *ngIf="level.typeId === '502'" slot="start" [name]="'home'"></ion-icon>
              <ion-icon *ngIf="level.typeId === '504'" slot="start" [name]="'pin'"></ion-icon>
              <ion-icon *ngIf="level.typeId === '599'" slot="start" [name]="'cube'"></ion-icon>
              <ion-label>
                {{level.name}}
              </ion-label>
            </ion-item>
          </div>

        </ion-list>
      </ion-content>
    </ion-menu>

    <ion-router-outlet id="context"></ion-router-outlet>

</ion-app>
