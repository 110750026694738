import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DataService } from '../../services/data.service';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.css'],
})
export class RedirectComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public dataService: DataService,
    private apiService: ApiService,
  ) { }

  ngOnInit() {
    console.log('Redirect route has been reached');

    // Add any additional logic you want to execute when this route is activated

    // Extract the token from the query parameters

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        var endpoint = event.url;
        let endpointSplited = endpoint.split('/'); // This splits the string into an array of segments
        let tokenFromPath = endpointSplited[endpointSplited.length - 1];
        // Use the last path part
        console.log('Last path part (token):', tokenFromPath);


        //SET TOKEN TO LOCAL STORAGE
        localStorage.setItem('token', tokenFromPath);


        /**
                     * AUTO LOGIN BY DEFAULT SINCE 2-JUNE-2021:
                     */
        localStorage.setItem('autologin', 'true');

        /**
         * Except set new password.
         */

        // if(window.location.pathname === '/passwd'){
        let request = '';
        if (window.location.search[0] === '?') {
          console.log('GOT HERE');
          request = window.location.search.split('?')[1].split('=')[1];
          this.router.navigate(['passwd'], { queryParams: { request: request } });
          return;
        }
        // }

        /** Auto Login if Token is available;
         *  Checks if has token and query the context, otherwise redirects to login.
         */

        if ('true' === localStorage.getItem('autologin')) { //TODO - DO NOT CONSIDER IT WHEN endpoint is /redirect
          console.log('GOT HERE 2');
          // Check expiration date here.
          if (this.dataService.checkTokenIsExpired(this.router, this.apiService) == false) {
            console.log('Token is not expired!');
            const token = localStorage.getItem('token');
            if (token) {
              this.dataService.setPermissions(JSON.parse(localStorage.getItem('permissions')));
              this.dataService.setCurrentCtxId(Number(localStorage.getItem('rootCtxId')));
              this.dataService.setCurrentCtxIdType(''); // TODO: grab rootCtxIdType at the moment of login
              console.log('QueryContext');
              this.apiService.queryCurrentContext(this.dataService.getCurrentCtxId(), this.dataService.getCurrentCtxIdType(), responseCtx => {
                this.dataService.initContext(responseCtx);
                this.dataService.chooseContext(this.dataService.contextChildren[0], 0, () => {
                  this.apiService.queryCurrentContext(this.dataService.getCurrentCtxId(), this.dataService.getCurrentCtxIdType(), responseCtx2 => {
                    this.dataService.updateContext(responseCtx2);
                  });
                });
              });
              this.router.navigateByUrl('home');
            } else {
              this.router.navigateByUrl('login');
            }
          } else {
            console.log('Token is expired!');
            this.dataService.logout(this.router, this.apiService);
          }

        } else {
          this.router.navigateByUrl('login');
        }

      }
    });
  }

}
